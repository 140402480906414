import Vue from 'vue';
import { WebsitesService }  from '../../common/api.service.js'
import ApiService from '../../common/api.service.js';
import { usePermissionsStore } from '../../store/PermissionStore.js';

// Data source summary components
import { ScrapeData } from "../../datasource/ScrapeData.js";
import { ExcludedItemList } from "../../datasource/ExcludedItemList.js";
import { RecentInventory } from "../../datasource/RecentInventory.js";
import { InventorySchedule } from "../../datasource/InventorySchedule.js";

// Import type collapses components
import { Shopify } from "./components/collapses/Shopify.js"
import { WooCommerce } from './components/collapses/WooCommerce.js';
import { Spreadsheet } from './components/collapses/Spreadsheet.js';
import { SiteWalk } from './components/collapses/SiteWalk.js';
import { SampleData } from './components/collapses/SampleData.js';

const DataSource = {
    name: "DataSource",
    props: {
        dataSourceTypeDict: Object,
        // For child components
        intervalSelector: Object,
        scrapeData: Object,
        excludedItemList: Object,
        recentInventory: Object,
        accountId: String,
        // Basic
        websiteName: String,
        hasMetaTesterRole: Boolean,
        staticUrl: String,
    },
    data() {
        return {
            isDataReady: false,
            websiteDetails: {
                importType: null,
                dataSourceParams: null,
                websiteUrl: null
            },
            importTypeInfos: {
                feed: {
                    dataSourceParams: 'feed_url',
                    importedAlertPrefix: "Feed"
                },
                file:{
                    dataSourceParams: 'import_file',
                    importedAlertPrefix: "CSV File Upload"
                },
                sheets:{
                    dataSourceParams: 'sheets_url',
                    importedAlertPrefix: "Google Sheet"
                },
                shopify: {
                    dataSourceParams: 'shop_name',
                    importedAlertPrefix: "Shopify Store"
                },
                spider:{
                    dataSourceParams: '', // spider take the website_url from website details, not from dataSourceParams
                    importedAlertPrefix: "Website"
                },
                woocommerce: {
                    dataSourceParams: 'website_url',
                    importedAlertPrefix: "WooCommerce Store"
                },
                sample_data: {
                    dataSourceParams: '',
                    importedAlertPrefix: "Sample Data",
                    importedDescription: "You are using a set of sample items provided by AdRetriever."
                }
            },
            // Matching website's import_type (aka backend's DatasourceType), we should always refer to this enum instead of using the types itself in case the types from backend changed
            importTypesEnum: {
                spreadsheet: {
                    types: {
                        sheets: {value:'sheets', label: "Google Sheet"},
                        file: {value: 'file', label: "File"},
                        feed: {value: 'feed', label: "Feed"},
                    },
                    value: 'spreadsheet'
                },
                shopify: {value: 'shopify'},
                spider: {value: 'spider'}, // Site walk
                woocommerce: {value: 'woocommerce'},
                sample_data: {value: 'sample_data'}
            },
            importTypeComponentMap: {
                shopify: Shopify,
                woocommerce: WooCommerce,
                spreadsheet: Spreadsheet,
                spider: SiteWalk, // Site walk
                sample_data: SampleData
            },
            importTypeConnectFunctionMap: {
                spreadsheet: this.connectDataSource,
                shopify: this.connectShopify,
                spider: this.connectDataSource,
                woocommerce: this.connectDataSource,
                sample_data: this.connectDataSource,
            },
            isShowEditBtn: true,
            permissionsStore : usePermissionsStore(),
            formData: {},
            formErrors: {},
            submitErrorMsg: '',
            activePlatform: null,
            activeSpreadsheetType: null,
            isFormHasError: false,
            isEdit: false,
            isSubmitting: false
        }
    },
    created() {
        // Generate formData object from importTypeComponentMap
        Object.keys(this.importTypeComponentMap).forEach(platform => {
          Vue.set(this.formData, platform, {})
        })
      },
    mounted() {
        this.getWebsiteData();
    },
    methods: {
        getWebsiteData() {
            const params = {
                expand: 'datasource_params'
            }

            WebsitesService.get(this.websiteName, params).then(response => {
                const { import_type, website_url, datasource_params, item_type } = response;
                this.websiteDetails = {
                    ...this.websiteDetails,
                     importType: import_type,
                     website_url: website_url,
                     dataSourceParams: datasource_params,
                     itemType: item_type
                };
                if(this.isWebsiteSelectedSpreadsheetType) {
                    this.activePlatform = this.importTypesEnum.spreadsheet.value
                    this.updateActiveSpreadsheetType(import_type)
                }else{
                    this.activePlatform = import_type
                }
                
                this.isDataReady = true
            })
        },
        updateDisplayOfEditBtn(isImporting) {
            this.isShowEditBtn = !isImporting
        },
        handleShow(platform) {
            this.activePlatform = platform
        },
        handleHide() {
            this.activePlatform = null
        },
        updateActiveSpreadsheetType(type) {
            this.activeSpreadsheetType = type
        },
        onSubmit() {
            if (this.importTypeConnectFunctionMap[this.activePlatform]) {
                this.isSubmitting = true
                this.importTypeConnectFunctionMap[this.activePlatform]();
            }
        },
        reset() {
            this.isSubmitting = false
        },
        /** Functions for different import type */
        connectDataSource() {
            this.clearSubmitError()

            // Convert active platform value to importTypesEnum (to match backend import type enum)
            const importType = this.isActivePlatformSpreadsheet ? this.importTypesEnum.spreadsheet.types[this.activeSpreadsheetType].value : this.importTypesEnum[this.activePlatform].value;

            if(!importType) {
                console.warn("Missing import type, abort submission.")
                return
            }

            let config = {}
            const { [this.activePlatform]: platformData } = this.formData;
            if(importType === this.importTypesEnum.sample_data.value) platformData['item_type'] = this.websiteDetails.itemType
            let paramsToSend = { 
                datasource_params: platformData,
                import_type : importType
             }

            // Determine if we need to use FormData
            const useFormData = this.activeSpreadsheetType === this.importTypesEnum.spreadsheet.types.file.value
            if (this.isActivePlatformSpreadsheet) {

                if (useFormData) {
                    const finalFormData = new FormData();
                    Object.entries(platformData).forEach(([key, value]) => {
                        finalFormData.append(key, value);
                    });
                    // Since we are not using paramsToSend for file import type, we need to append the `import_type` in the formData
                    finalFormData.append('import_type', importType);
                    paramsToSend = finalFormData;
                    config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                } 
            }

            // The value of importType will differ if `isActivePlatformSpreadsheet` is true; therefore, we have deferred the assignment until this point.
            paramsToSend.import_type = importType

            this.updateWebsite(paramsToSend, config);
        },
        updateWebsite(paramsToSend, config) {
            WebsitesService.update(`${this.websiteName}/`, paramsToSend, config).then(response => {
                this.onConnectDataSourceSuccessCallback()
            }).catch(response => this.onConnectDataSourceFailCallback(response))
        },
        onConnectDataSourceSuccessCallback() {
            location.reload();
        },
        onConnectDataSourceFailCallback(response) {
            this.reset()
            this.submitErrorMsg = response.response.data.error
        },
        clearSubmitError() {
            this.submitErrorMsg = ''
        },
        connectShopify(isFormSubmit = true){
            this.clearSubmitError()

            const params = isFormSubmit ? {
                ...this.formData[this.activePlatform]
             } : {}
            ApiService.doRequest('get', `../api/${this.websiteName}/shopify/install`, {
               ...params
            }).then(response => {
                this.onConnectShopifySuccessCallback(response)
            })
        },
        onConnectShopifySuccessCallback(response) {
            this.reset()
            window.open(response.auth_url, '_blank')
        },
        updateIsEdit(value){
            this.isEdit = value
        },
        onChildFormChange(data, type) {
            this.formData[type] = data
        },
        updateFormError(type, error) {
            this.formErrors[type] = Object.assign({}, error);
            // Check if any form has errors
            const hasErrors = Object.values(this.formErrors).some(errors =>
                Object.keys(errors).length > 0
            );
            this.isFormHasError = hasErrors;
        },
        // Format the field name for child component to display in the error section and data source field
        formatFieldName(fieldName) {
            return fieldName
            .replace('_', ' ')
            .replace(/\w\S*/g,
                word => word.charAt(0).toUpperCase() +
                    word.substr(1).toLowerCase()
            );
        },
        onCancel() {
            this.updateIsEdit(false);
            this.clearSubmitError()
            this.reset()
        },
        syncChildFormWithWebsiteDetails(childForm, childClearFieldError, importType, updateFormInputValue = null) {
            // Determine if we're in spreadsheet import mode and validate the type
            const isSpreadsheetFlow = importType === this.importTypesEnum.spreadsheet.value &&
                this.isSpreadsheetType(this.websiteDetails.importType);

            if (!isSpreadsheetFlow && this.websiteDetails.importType !== importType && importType !== this.importTypesEnum.spider.value) {
                return;
            }

            Object.entries(childForm).forEach(([key, value]) => {
                if (isSpreadsheetFlow && key === this.websiteDetails.importType) {
                    // Handle nested spreadsheet field mapping
                    Object.entries(value).forEach(([spreadsheetSourceField, _]) => {
                        const sourceValue = this.websiteDetails.dataSourceParams[spreadsheetSourceField];

                        // We are not displaying previously uploaded file info
                        if (this.websiteDetails.importType !== this.importTypesEnum.spreadsheet.types.file.value) {
                            updateFormInputValue?.(this.websiteDetails.importType,
                                spreadsheetSourceField,
                                sourceValue);
                        }

                        childClearFieldError(spreadsheetSourceField);
                    });
                } else if (!isSpreadsheetFlow && Object.prototype.hasOwnProperty.call(childForm, key)) {
                    const sourceValue = this.websiteDetails.dataSourceParams[key] || this.websiteDetails[key]
                    childForm[key] = sourceValue;
                }
            });
        },
        isSpreadsheetType(type) {
            return Object.values(this.importTypesEnum.spreadsheet.types).map(typeInfo => typeInfo.value).includes(type);
        },
        getSampleData() {
            ApiService.doRequest('get', `../api/${this.websiteName}/settings/data_import/download_sample_data`).then(response => {
                this.handleGetFileSuccess(response, this.importTypesEnum.sample_data.value)
            })
        },
        getTemplate(sourceType) {
            ApiService.doRequest('post', `../api/${this.websiteName}/settings/data_import/${sourceType}/download_schema`).then(response => {
                this.handleGetFileSuccess(response, sourceType)
            })
        },
        handleGetFileSuccess(response, sourceType) {
            try {
                if (sourceType === this.importTypesEnum.spreadsheet.types.sheets.value) {
                    // Open Google Sheet Url
                    if (response.redirect_url) {
                        window.open(response.redirect_url, '_blank')
                        return;
                    }
                }

                // Handle CSV download
                const blob = new Blob([response.content], { type: 'application/csv' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = response.filename;
                link.click();
            } catch (error) {
                console.error('Failed to download schema:', error)
            }
        }
    },
    computed: {
        editDataSourceUrl(){
            if(!this.websiteDetails.importType) return null
            return `./settings/data_source/${this.websiteDetails.importType}`
        },
        importFromValue() {
            if(!this.websiteDetails.dataSourceParams) return null
            if(this.websiteDetails.importType === this.importTypesEnum.spider.value) return this.websiteDetails.website_url
            if(this.websiteDetails.importType === this.importTypesEnum.sample_data.value) return this.importTypeInfos[this.websiteDetails.importType].importedDescription

            const dataSourceParamsKey =  this.importTypeInfos[this.websiteDetails.importType].dataSourceParams
            const returnValue = this.websiteDetails.dataSourceParams[dataSourceParamsKey]
            if(this.websiteDetails.importType === this.importTypesEnum.shopify.value) return `https://${returnValue}.myshopify.com`

            return returnValue
        },
        isShowShopifyConnectMsg() {
            return (this.websiteDetails.importType === this.importTypesEnum.shopify.value && !this.websiteDetails.dataSourceParams.is_configured)
        },
        isShowInventorySchedule(){
            return this.websiteDetails.dataSourceParams.is_configured && this.websiteDetails.dataSourceParams.can_schedule
        },
        isShowUpgradeWarning(){
            return this.websiteDetails.importType === this.importTypesEnum.spider.value && !this.hasSiteWalkPermission
        },
        hasSiteWalkPermission() {
            return this.permissionsStore.permissions.tiered_custom_import_start
        },
        billingPageUrl() {
            return `../account/${this.accountId}/billing`
        },
        importTypeComponentProps() {
            return {
                staticUrl: this.staticUrl,
                websiteDetails: this.websiteDetails,
                importTypesEnum: this.importTypesEnum,
                isSubmitting: this.isSubmitting,
                websiteName: this.websiteName,
                accountId: this.accountId,
                activePlatform: this.activePlatform,
                hasSiteWalkPermission: this.hasSiteWalkPermission,
                billingPageUrl: this.billingPageUrl,
                formatFieldName: this.formatFieldName,
                isWebsiteSelectedSpreadsheetType: this.isWebsiteSelectedSpreadsheetType,
                getTemplate: this.getTemplate,
                getSampleData: this.getSampleData
            }
        },
        canSubmit() {
            if(this.activePlatform === this.importTypesEnum.sample_data.value) return true

            if (this.isFormHasError) {
                Object.keys(this.formErrors).forEach(importTypeKey => {
                    if (importTypeKey === this.activePlatform) {
                        return false;
                    }
                });
            }

            // Verify has non-empty formData
            if (!this.activePlatform || typeof this.formData[this.activePlatform] !== 'object') {
                return false;
            }

            const formObject = this.formData[this.activePlatform]
            if(this.activePlatform === this.importTypesEnum.spreadsheet.value) {
                if(!this.activeSpreadsheetType) return false
            }

            if(Object.keys(formObject).length === 0) return false

            // Iterate through all fields in the active platform's formData
            for (const key in this.formData[this.activePlatform]) {
                const value = this.formData[this.activePlatform][key];

                // Check if the value is null, undefined, or empty
                if (value === null || value === undefined || value.length === 0) {
                    return false;
                }
                // File handling
                if (value && typeof value === 'object' && 'name' in value && 'size' in value) {
                    if (!value.name || value.size === 0) {
                        return false;
                    }
                }
            }

            return true;
        },
        isActivePlatformSpreadsheet() {
            return this.activePlatform === this.importTypesEnum.spreadsheet.value
        },
        isWebsiteSelectedSpreadsheetType() {
            return this.isSpreadsheetType(this.websiteDetails.importType)
        },
        // Sort importTypeComponentMap by websiteDetails.importType
        sortedImportTypeComponentMap() {
            const entries = Object.entries(this.importTypeComponentMap);

            const sortedEntries = entries.sort((a, b) => {
                // First check if either entry matches the current import type
                const aMatches = a[0] === this.websiteDetails.importType;
                const bMatches = b[0] === this.websiteDetails.importType;

                if (aMatches !== bMatches) {
                    return bMatches - aMatches;
                }

                const aIsSpreadsheet = a[0] === this.importTypesEnum.spreadsheet.value
                const bIsSpreadsheet = b[0] === this.importTypesEnum.spreadsheet.value

                if (this.isWebsiteSelectedSpreadsheetType) {
                    // If current website is spreadsheet type, move spreadsheet to top
                    if (aIsSpreadsheet !== bIsSpreadsheet) {
                        return bIsSpreadsheet - aIsSpreadsheet;
                    }
                }

                return 0;
            });

            return Object.fromEntries(sortedEntries);
        },
    },
    components: {
        ScrapeData,
        ExcludedItemList,
        RecentInventory,
        InventorySchedule
    },
    template: `
    <div class="data-source-container mb-5">
        <b-alert :show="isShowUpgradeWarning && !isEdit" variant="warning" class="eggplant-color">
            <font-awesome-icon :icon="['fas', 'triangle-exclamation']" />     
            Your current plan does not support the custom site walk as a data source. Please <a class="eggplant-color font-weight-bold" :href="billingPageUrl"><u>upgrade</u></a> your plan or <a class="eggplant-color font-weight-bold" @click="updateIsEdit(true)"><u>update</u></a> your data source to continue.
        </b-alert>

        <div class="d-flex justify-content-between mb-3">
            <h5>Data Source</h5>
            <div v-if="websiteDetails.importType && !isEdit && isDataReady">
                <div v-if="!hasMetaTesterRole && isShowEditBtn" class="">
                    <b-button class="edit-btn" variant="secondary" @click="updateIsEdit(true)">
                        Edit
                    </b-button>  
                </div>
            </div>
        </div>

        <div v-if="!isDataReady" class="spinner-container text-center">
            <b-spinner></b-spinner>
        </div>

        <div v-else class="data-source-content">
             <div v-if="websiteDetails.importType && !isEdit">

                <div v-if="!hasMetaTesterRole && isShowEditBtn && isDataReady" class="col-1">
                    <b-button class="align-self-end position-absolute edit-btn" variant="secondary" @click="updateIsEdit(true)">
                        Edit
                    </b-button>  
                </div>

                <b-card class="data-source-summary-container container p-0" header-class="bg-white">
                    <template #header>
                        <div class="row no-gutters p-2 align-items-top">
                            <div class="col-1 mr-4">
                                <font-awesome-icon v-if="websiteDetails.importType === importTypesEnum.sample_data.value" :icon="['fas', 'box-open']" class="sample-data-icon" />
                                <img v-else class="img-thumbnail border-0" :src="staticUrl + 'img/data_source/' + websiteDetails.importType +'.png'">
                            </div>

                            <div class="d-flex flex-column col-10">
                                <div v-if="importFromValue" class="text-break">
                                    <span class="font-weight-bold">{{ importTypeInfos[websiteDetails.importType].importedAlertPrefix }}:</span>
                                    <br>{{ importFromValue }}
                                </div>
                            </div>
                        </div>
                    </template>

                    <div v-if="isShowShopifyConnectMsg" class="row no-gutters">
                        <div class="col-11 d-flex border bg-light rounded p-3 align-items-center justify-content-between mb-4">
                            <b>You're almost ready to import from Shopify...</b> 
                            <b-button variant="primary" @click="connectShopify(false)">
                                Connect
                            </b-button>                   
                        </div>
                    </div>
                    <ScrapeData v-bind="{ ...scrapeData }" :staticUrl="staticUrl" :accountId="accountId" @updateDisplayOfEditBtn=updateDisplayOfEditBtn class="mb-4" :formatFieldName="formatFieldName"/>
                    <ExcludedItemList v-bind={...excludedItemList} class="mb-4" />
                    <RecentInventory v-bind={...recentInventory} class="mb-4" />
                    <InventorySchedule v-if="isShowInventorySchedule" :intervalSelector=intervalSelector class="mb-4" />
                </b-card>
             </div>
            
            <div v-else>
                <b-alert v-if="!websiteDetails.importType" variant="info" show>Please choose your data source so AdRetriever can begin collecting items for your ads.</b-alert>
                <FormulateForm>
                    <div class="import-type-components position-relative">
                        <div v-if="isSubmitting" class="loading-overlay bg-white opacity-50 page-loading-overlay position-absolute d-flex justify-content-center align-items-center default-theme-border-radius">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>
                        <component
                        v-for="(component, type) in sortedImportTypeComponentMap"
                        :key="type"
                        :is="component"
                        :importType="type"
                        v-bind="{...importTypeComponentProps}"
                        class="import-type-collapse mb-4"
                        :class="activePlatform === type ? 'expanded' : ''"
                        @show="handleShow(type)"
                        @hide="handleHide()"
                        @onFormChange="onChildFormChange"
                        @updateActiveSpreadsheetType="updateActiveSpreadsheetType"
                        @updateFormError="updateFormError"
                        @update:isOpen="activePlatform = $event"
                        @syncFormWithWebsiteDetails="syncChildFormWithWebsiteDetails"
                        />
                    </div>
                    <b-alert v-if="submitErrorMsg.length > 0" show variant="danger" class="text-break"> 
                        <font-awesome-icon :icon="['fas', 'triangle-exclamation']" class="mr-1" size="lg"/> 
                        {{ submitErrorMsg }}
                    </b-alert>
                    <b-button 
                    variant="primary" 
                    class="mt-3"
                    :disabled="!canSubmit || isSubmitting"
                    @click="onSubmit"
                    >
                        Save
                    </b-button>

                    <b-button 
                    variant="secondary" 
                    class="mt-3"
                    :disabled="isSubmitting"
                    @click="onCancel"
                    >
                        Cancel
                    </b-button>
                </FormulateForm>
            </div>
        </div>
    </div>
    `
}

export { DataSource };
