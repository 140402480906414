import Vue from 'vue';
import VueFormulate from "@braid/vue-formulate";

// Utility functions
import { debounce } from "lodash";
import { validateUrl } from '../../../../common/helpers.js';
import ApiService from '../../../../common/api.service.js';

Vue.use(VueFormulate);

const Spreadsheet = {
    name: "Spreadsheet",
    props: {
        staticUrl: String,
        websiteDetails: Object,
        importTypesEnum: Object,
        importType: String, // spreadsheet
        isSubmitting: Boolean,
        websiteName: String,
        activePlatform: String,
        isWebsiteSelectedSpreadsheetType: Boolean,
        formatFieldName: {
            type: Function,
            default: (value) => value // Safe default
        },
        getTemplate: {
            type: Function,
            default: (value) => value // Safe default
        },
    },
    data() {
        return {
            isSubmitted: false,
            sourceType: null, // sheets/file/feed
            form: {},
            formLabels: {
                sheets_url: "Sheet URL",
                feed_url: "Feed URL"
            },
            errors: {},
            formFields: {
                sheets: ['sheets_url'],
                file: ['import_file'],
                feed: ['feed_url', 'username', 'password']
            },
            isFormInitialized: false
        }
    },
    mounted () {
        this.setUpDefaultSourceType()
        this.initializeForm()
    },
    methods: {
        setUpDefaultSourceType() {
            const newSourceType = this.isWebsiteSelectedSpreadsheetType ? this.websiteDetails.importType : this.importTypesEnum.spreadsheet.types.sheets.value
            this.assignSourceType(newSourceType)
        },
        initializeForm() {
            Object.entries(this.formFields).forEach(([type, fields]) => {
                this.form[type] = {};
                fields.forEach(field => {
                  this.form[type][field] = '';
                });
            });
            this.isFormInitialized = true
        },
        assignSourceType(type) {
            this.sourceType = type
        },
        validateUrl(fieldName) {
            const result = validateUrl(this.form[this.sourceType][fieldName], false)
            if (result.error) {
                this.errors = {
                    ...this.errors,
                    [fieldName]: result.error
                };
            } else {
                this.clearFieldError(fieldName);
            }
        },
        clearFieldError(field) {
            if (Object.prototype.hasOwnProperty.call(this.errors, field)) {
                // Create a new object to avoid mutating the original
                // This is important because Vue.js reactivity system works by tracking changes 
                // in mutable objects. If we directly modify the original errors object,
                // Vue may not detect the change and trigger re-computation of dependent computed properties (hasErrors).
                const newErrors = { ...this.errors };
                delete newErrors[field];
                this.errors = newErrors;
            }
        },
        clearErrors() {
            this.errors = {};
        },
        formatName(fieldName) {
            return this.formLabels[fieldName] || this.formatFieldName(fieldName)
        },
        getIconUrl(type) {
            return `${this.staticUrl}img/data_source/${type}.png`
        },
        handleFileUploadComplete(event) {
            // Get uploaded file
            const file = event.file
            
            // Store the file in our data property
            this.updateFormInputValue(this.importTypesEnum.spreadsheet.types.file.value, 'import_file', file)
        },
        updateFormInputValue(type, field, val) {
            const newForm = { ...this.form }
            newForm[type][field] = val
            this.form = Object.assign({}, this.form, newForm)
        },
    },
    watch: {
        form: {
            deep: true,
            handler: debounce(function (newValue) {
                const currentTypeFormValues = newValue[this.sourceType]
                if(currentTypeFormValues) this.$emit('onFormChange', { ...currentTypeFormValues }, this.importType)
            }, 500),
        },
        sourceType(newValue) {
            this.clearErrors()
            this.$emit("updateActiveSpreadsheetType", newValue)
        },
        hasErrors: {
            deep: true,
            handler: function () {
                this.$emit('updateFormError', this.importType, this.errors)
            },
        },
        isFormInitialized(newVal) {
            if (newVal) this.$emit("syncFormWithWebsiteDetails", this.form, this.clearFieldError, this.importType, this.updateFormInputValue)
        }
    },
    computed: {
        isDoneSettingUp() {
            return this.websiteDetails.importType === this.importTypesEnum.woocommerce.value && this.websiteDetails.datasource_params?.is_configured
        },
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
        spreadsheetOptions() {
            return Object.entries(this.importTypesEnum.spreadsheet.types).map(([key, info]) => ({
                value: key,
                label: info.label,
            }))
        },
        isShow: {
            get() {
                return this.activePlatform === this.importType;
            },
            set(newValue) {
                this.$emit('update:activePlatform', newValue);
            }
        }
    },
    template: `
    <b-card no-body class="mb-1 border-0 default-theme-border-radius">
        <b-card-header header-tag="header" v-b-toggle.spreadsheet-accordion class="p-2 border-0 bg-white d-flex align-items-center eggplant-color default-theme-border-radius cursor-pointer" role="button">
            <font-awesome-icon class="import-type-icon mr-3" :icon="['fas', 'table']" />
            <p class="m-0 text-left">
                <span class="font-weight-800">Spreadsheet</span>
                <span>Import inventory from a spreadsheet</span>
            </p>
        </b-card-header>

        <b-collapse id="spreadsheet-accordion" accordion="import-type-accordion" role="tabpanel" @show="$emit('show')" v-model="isShow">
            <b-card-body class="position-relative">

                <div v-if="isDoneSettingUp">
                
                </div>

                <div v-else>
                    <b-alert v-if="hasErrors" variant="danger" dismissible @dismissed="clearErrors" show>
                        <b class="alert-heading">Validation Errors</b>
                        <ul class="mb-0">
                            <li v-for="(message, fieldName) in errors" :key="fieldName">
                                {{ formatName(fieldName) }}: 
                                {{ message }}
                            </li>
                        </ul>
                    </b-alert>
                    
                    <div class="form-group">
                        <FormulateInput
                        type="radio"
                        name="import-type"
                        :options="spreadsheetOptions"
                        v-model="sourceType"
                        :wrapper-class="'wrapper d-flex'"
                        :element-class="'hidden'"
                        >
                            <template #label="{ options }">
                                
                                    <div v-for="(option, index) in options" :key="index" class="col-2 source-type-radio-btn">
                                        <label 
                                        class="d-flex flex-column p-3 rounded-lg cursor-pointer"
                                        :class="{ 'border selected': sourceType === option.value }"
                                        >
                                            <input v-model="sourceType" type="radio" :value="option.value" class="hidden">
                                            <img :src="getIconUrl(option.value)" class=""/>
                                            <span class="text-center">{{ option.label }}</span>
                                        </label>
                                    </div>
                            </template>
                        </FormulateInput>

                        <transition name="fade" mode="out-in">
                            <!-- Google Sheet -->
                            <div v-if="sourceType === importTypesEnum.spreadsheet.types.sheets.value" class="spreadsheet-input-form" :key="importTypesEnum.spreadsheet.types.sheets.value">
                                <p>Complete the template and enter the sheet URL below</p>
                                <b-button @click="getTemplate(importTypesEnum.spreadsheet.types.sheets.value)">
                                    Open Template
                                </b-button>
                                <font-awesome-icon id="google-sheet-template-explain" icon="fas fa-info-circle" class="ml-1" />
                                <b-tooltip target="google-sheet-template-explain" triggers="hover">
                                    Ensure the share settings are set to Anyone with the link
                                </b-tooltip>

                                <FormulateInput
                                :disabled="isSubmitting"
                                label="Google Sheet URL"
                                id="sheets-url"
                                type="text"
                                name="sheets_url"
                                placeholder="https://docs.google.com/spreadsheets/d/abcde"
                                :input-class="'form-control border-0 mb-0'"
                                :label-class="'col-3'"
                                :wrapper-class="'row align-items-baseline'"
                                :element-class="'d-flex border align-items-center rounded col-8 p-0'"
                                :outer-class="'mb-3'"
                                required
                                @input="(val) => updateFormInputValue(importTypesEnum.spreadsheet.types.sheets.value, 'sheets_url', val)"
                                v-model="form[importTypesEnum.spreadsheet.types.sheets.value].sheets_url"
                                :autofocus="true"
                                @blur="validateUrl('sheets_url')" />
                            </div>

                            <!-- File -->
                            <div v-if="sourceType === importTypesEnum.spreadsheet.types.file.value" class="spreadsheet-input-form" :key="importTypesEnum.spreadsheet.types.file.value">
                                <p>Complete the template and upload the file below</p>
                                <b-button @click="getTemplate(importTypesEnum.spreadsheet.types.file.value)">
                                    Download Template
                                </b-button>
                                <font-awesome-icon id="google-sheet-template-explain" icon="fas fa-info-circle" class="ml-1" />
                                <b-tooltip target="google-sheet-template-explain" triggers="hover">
                                    Only CSV files are accepted
                                </b-tooltip>

                                <FormulateInput
                                :disabled="isSubmitting"
                                type="file"
                                name="import_file"
                                label="Choose a file"
                                help="Select a file to upload"
                                validation="mime:text/csv"
                                accept=".csv"
                                @file-upload-complete="handleFileUploadComplete"
                                />
                            </div>

                            <!-- Feed -->
                            <div v-if="sourceType === importTypesEnum.spreadsheet.types.feed.value" class="spreadsheet-input-form" :key="importTypesEnum.spreadsheet.types.feed.value">
                                <p>Complete the template and enter your credentials</p>
                                <b-button @click="getTemplate(importTypesEnum.spreadsheet.types.feed.value)">
                                    Download Template
                                </b-button>
                                <font-awesome-icon id="google-sheet-template-explain" icon="fas fa-info-circle" class="ml-1" />
                                <b-tooltip target="google-sheet-template-explain" triggers="hover">
                                    These credentials will be used for HTTP Basic authentication
                                </b-tooltip>

                                <FormulateInput
                                :disabled="isSubmitting"
                                label="Feed URL"
                                id="feed-url"
                                type="text"
                                name="feed_url"
                                placeholder="https://yourservice.com/feed"
                                :input-class="'form-control border-0 mb-0'"
                                :label-class="'col-3'"
                                :wrapper-class="'row align-items-baseline'"
                                :element-class="'d-flex border align-items-center rounded col-8 p-0'"
                                :outer-class="'mb-3'"
                                required
                                @input="(val) => updateFormInputValue(importTypesEnum.spreadsheet.types.feed.value, 'feed_url', val)"
                                v-model="form[importTypesEnum.spreadsheet.types.feed.value].feed_url"
                                :autofocus="true"
                                @blur="validateUrl('feed_url')" />

                                <FormulateInput
                                :disabled="isSubmitting"
                                label="Username"
                                id="username"
                                type="text"
                                name="username"
                                placeholder="username"
                                :input-class="'form-control border-0 mb-0'"
                                :label-class="'col-3'"
                                :wrapper-class="'row align-items-baseline'"
                                :element-class="'d-flex border align-items-center rounded col-8 p-0'"
                                :outer-class="'mb-3'"
                                required
                                v-model="form[importTypesEnum.spreadsheet.types.feed.value].username"
                                @input="(val) => updateFormInputValue(importTypesEnum.spreadsheet.types.feed.value, 'username', val)"
                                />

                                <FormulateInput
                                :disabled="isSubmitting"
                                label="Password"
                                id="password"
                                type="password"
                                name="password"
                                placeholder="password"
                                autocomplete="new-password"
                                :input-class="'form-control border-0 mb-0'"
                                :label-class="'col-3'"
                                :wrapper-class="'row align-items-baseline'"
                                :element-class="'d-flex border align-items-center rounded col-8 p-0'"
                                :outer-class="'mb-3'"
                                required
                                v-model="form[importTypesEnum.spreadsheet.types.feed.value].password"
                                @input="(val) => updateFormInputValue(importTypesEnum.spreadsheet.types.feed.value, 'password', val)"
                                />

                            </div>
                         </transition>
                    </div>
                </div>
            </b-card-body>
        </b-collapse>
    </b-card>
    `
}

export { Spreadsheet };
