import {defineStore} from 'pinia';
import {isEmpty, merge} from 'lodash';

// Regular expression to locate the part of a string before a `.`  Used to strip off `.Fill 1` portions of layer names
// appended during export of Lottie animations from After Effects
const stripDotSuffixRegex = /^([a-z0-9\-_]*)\..*$/i;

export const useDHVideoAdEditStore = defineStore('dhVideoAdEditStore', {
    state: () => ({
        env: null,
        advertiserItemType: null,
        lastSelectedTemplate: null,
        dhAccessToken: '',
        // Actual template list
        templateList: [],
        customTemplateList: [],
        standardTemplateBrandName: 'standard_brand',
        // Sizes based templates
        allAvailableSizes: [],
        // Specify the required fields to retrieve the logo from the asset API, with restricted naming due to the reuse of LogoCardList
        logoDetailFields: 'id,name,asset,default,website,palette',
        // Element with these class will be updated content when item change
        itemFields: [],
        // List of essential classes that must be present in the Design Huddle project (template)
        essentialClasses: [
            'logo', 'image'
        ],
        // List of dimensions for templates, ordered by priority. The first dimension will be at the top of the list in grouped templates and ad.projects
        priorityTemplateDimensions: [
            { width: 1920, height: 1080 },
            { width: 1080, height: 1080 },
            { width: 1080, height: 1920 }
        ],
        tempSelectedItemset: null,
        // The actual Ad that user editing in preview
        ad: {
            name: "",
            itemset: null,
            // items get from tempSelectedItemset (new create) / itemSet (edit)
            itemList: [],
            // Updated when user selected a template
            templates: [],
            // At least 3 projects per ad to display different sizes
            projects: [],
            lottieElementLayers: [],
            editorData: null, // TODO default content for new ads? (Or we have that in DH already)
            editorDataSnapshot: null, // Storing the previous project data, ready to apply to new project via updateElements
            logo: null,
            selectedSizeProjectId: null,
            currentItemIndex: 0 // Indicate the item index that being used in the editor from itemList
        },
        // Default and the actual palette using in brand section colour pickers
        colourPalette: {
            primary: "#00B0B8",
            tertiary: "#FFFFFF",
            secondary: "#F9F7EC",
            primary_text: "#FFFFFF",
            tertiary_text: "#171718",
            secondary_text: "#272727"
        },
        // TODO update default template name if we have created in DH
        itemTypeToDefaultThemeMap: {
            product: "Spotlight",
            property: "Real Estate",
            vehicle: null,
            event: null,
            job: null,
            lottery: null
        },
        // FIXME: Only implement for LogoCardList to reference, we will have to update LogoCardList int he future to not referring the website id from the store.state
        state: {
            websiteId: ''
        },
        states: {
            isEditMode: false, // Default false
            isCreateProjectDone: false, // Turn to true when all sizes created
            isTemplateLoaded: false,
            isCustomTemplateLoaded: false,
            isItemSetLoaded: false,
            isItemsLoaded: false,
            isSwitchingSize: false,
            isEditorReady: false,
            isUpdatedNewProjects: false, // Track if we updated all the elements inside newly created project
            isShowBrandSection: false,
            isLoading: {
                status: false,
                isShowSpinner: true, // Default true
                message: ''
            }
        },
        errors: {
            adNameError: null
        },
        loadingMessages: {
            initializing: 'Initializing...',
            creatingProject: 'Setting up your ad...',
            cloningProjects: 'Checking out your ad for editing...',
            refreshingToken: 'Refreshing editor...',
            authError: 'Failed to configure editor after multiple attempts. Please try again later. If this persists please contact support.',
            generalError: 'Something went wrong. Please try again later. If this persists please contact support.',
            applyingTheme: 'Applying theme...'
        },
    }),
    actions: {
        // FIXME: Only implement for LogoCardList to reference, we will have to update LogoCardList int he future to not referring the website id from the store.state
        updateWebsiteId(id) {
            this.state.websiteId = id
        },
        updateEnv(env) {
            this.env = env
        },
        updateDHAccessToken(token) {
            this.dhAccessToken = token
        },
        updateSelectedSize(sizeProjectId) {
            this.ad.selectedSizeProjectId = sizeProjectId
        },
        updateState(stateKey, value) {
            console.log("updateState", stateKey, value)
            this.states[stateKey] = value
        },
        updateLottieElementLayers(layersByElementID) {
            this.ad.lottieElementLayers = layersByElementID;
        },
        updateAdName(name) {
            this.ad.name = name
        },
        updateWebsiteData(response) {
            this.advertiserItemType = response.item_type
            this.lastSelectedTemplate = response.last_selected_video_template
        },
        updateTemplateList(templateList, brandCode) {
            if(brandCode === this.standardTemplateBrandName){
                this.templateList = templateList
                if (!this.states.isTemplateLoaded) this.updateState('isTemplateLoaded', true)
            }else{
                this.customTemplateList = templateList
                if (!this.states.isCustomTemplateLoaded) this.updateState('isCustomTemplateLoaded', true)
            }

            this.prioritizeSelectedTemplate()
        },
        updateError(errorKey, value) {
            this.errors[errorKey] = value
        },
        prioritizeSelectedTemplate() {
            if (!this.ad.templates.length) return

            const highlightedTemplateId = this.ad.templates[0].template_id

            // Check if highlightedTemplateId exists in this.themes or this.customTemplateList
            let themeIndex = this.templateList.findIndex(template => template.template_id === highlightedTemplateId);
            let isCustom = false;

            if (themeIndex === -1) {
                // If not found in this.templateList, look in this.customTemplateList
                themeIndex = this.customTemplateList.findIndex(theme => theme.template_id === highlightedTemplateId);
                isCustom = true;
            }

            // If highlightedTemplateId is not found in both lists, exit the function
            if (themeIndex === -1) return;

            // Extract the highlighted theme
            const highlightedTheme = isCustom ? this.customTemplateList.splice(themeIndex, 1)[0] : this.templateList.splice(themeIndex, 1)[0];

            // Place the highlighted theme at the beginning of the appropriate list
            if (isCustom) {
                this.customTemplateList.unshift(highlightedTheme);
            } else {
                this.templateList.unshift(highlightedTheme);
            }
        },
        switchToNextItem() {
            const newIndex = this.ad.currentItemIndex + 1
            if (this.ad.currentItemIndex >= this.ad.itemList.length - 1) {
                this.ad.currentItemIndex = 0
            } else {
                this.ad.currentItemIndex = newIndex
            }
        },
        clearAdProjects() {
            this.ad.projects = []
        },
        addAdProjects(projects) {
            // Each project needs to be an object as later on we will populate each of the project data in to correspondence object
            this.ad.projects.push(...projects)
        },
        updateProjectData(projectId, data) {
            const projectIndex = this.ad.projects.findIndex(project => project.project_id === projectId);
            if (projectIndex !== -1) {
                Object.assign(this.ad.projects[projectIndex], { ...this.ad.projects[projectIndex], ...data });
            }
            this.ad.projects = this.sortByPriorityDimensions(this.ad.projects)
        },
        updateItemFields(fields) {
            this.itemFields = fields;
        },
        // Update single colour
        updateColourPalette(key, value) {
            if (Object.prototype.hasOwnProperty.call(this.colourPalette, key)) {
                this.colourPalette[key] = value
            }
        },
        updateFullColourPalette(palette) {
            Object.assign(this.colourPalette, palette)
        },
        // Update the whole editor data with project data from DH
        updateEditorData(data) {
            const newData = {
                scenes: {
                    ...data.scenes
                }
            }
            this.ad.editorData = newData
        },
        // Triggered when user selected an item set in item set modal
        updateTempSelectedItemSet(itemset) {
            this.tempSelectedItemset = itemset
        },
        updateItemset(newItemset) {
            this.ad.itemset = newItemset
            this.resetCurrentItemIndex()
        },
        // Called when first loaded into the page to create a new ad OR editing an ad
        setItemSetWith(itemSetRes) {
            if (Array.isArray(itemSetRes.results) && itemSetRes.results.length > 0 ||
                typeof itemSetRes === 'object' && !Array.isArray(itemSetRes)) {
                const itemToUse = Array.isArray(itemSetRes.results) ? itemSetRes.results[0] : itemSetRes;
                this.updateItemset(itemToUse);
                this.updateTempSelectedItemSet(itemToUse);
            }
            this.updateState('isItemSetLoaded', true)
            // this.state.ready = this.state.isTemplateLoaded && this.state.isItemSetLoaded
        },
        updateCurrentItemAccessibility(value) {
            const item = this.ad.itemList[this.ad.currentItemIndex];
            this.ad.itemList.splice(this.ad.currentItemIndex, 1, Object.assign({}, item, { accessibility: value }));
        },
        updateItemList(items) {
            this.ad.itemList = items
            this.updateState('isItemsLoaded', true)
        },
        resetCurrentItemIndex() {
            this.ad.currentItemIndex = 0
        },
        updateTemplateVariablePreviewUrl(templateId, previewUrl, itemId, brandCode) {
            let updatedAdTemplate;

            // Check if the brandCode matches the custom template brand name
            const isCustomTemplate = brandCode === this.getCustomTemplateBrandName;

            // Choose the appropriate template list based on the brandCode
            const templateList = isCustomTemplate ? this.customTemplateList : this.templateList;


            // First, check if the template exists in templateList
            updatedAdTemplate = templateList.find(t => t.template_id === templateId);

            // If not found in templateList, check customTemplateList
            if (!updatedAdTemplate) {
                updatedAdTemplate = this.customTemplateList.find(t => t.template_id === templateId);
            }

            if (updatedAdTemplate) {
                let index = templateList.findIndex(t => t.template_id === templateId);
                if (index === -1) {
                    // If not found in templateList, find in customTemplateList
                    index = this.customTemplateList.findIndex(t => t.template_id === templateId);
                }

                if (index !== -1) {
                    const newTemplate = {
                        ...updatedAdTemplate,
                        // Indicates that the variable template preview is generated for this template
                        // This field is used by DHAdThemeCardList to determine whether to generate VTP or not
                        vtp_generated: true,
                        vtp_item_id: itemId, // The item used in the preview
                        thumbnail_url: previewUrl // Update the preview_url directly so we can use AdThemeCard without updating it
                    };

                    // Update the correct list
                    if (isCustomTemplate) {
                        this.customTemplateList[index] = newTemplate;
                    } else {
                        this.templateList[index] = newTemplate;
                    }
                } else {
                    console.warn(`Template with ID ${templateId} not found in either templateList or customTemplateList`);
                }
            } else {
                console.warn(`Template with ID ${templateId} not found in either templateList or customTemplateList`);
            }
        },
        // TODO: Do we need this anymore?
        //       As of this writing, this is only used for a warning that there are no elements with "logo" class
        getElementsByClassName(eleClassName) {
            if (!this.ad.editorData) return

            try {
                // Convert scenes object to an array
                const scenesArray = Object.values(this.ad.editorData.scenes);

                const matchingElements = scenesArray.reduce((acc, scene) => {
                    Object.values(scene.elements).forEach(element => {
                        if ((Array.isArray(element.element_classes) && element.element_classes.includes(eleClassName)) ||
                            (typeof element.element_classes === 'string' && element.element_classes === eleClassName)) {
                            acc.push(element);
                        }
                    });
                    return acc;
                }, []);

                return matchingElements.length > 0 ? matchingElements : null;
            } catch (error) {
                console.error("Error in getElementsByClassName:", error);
                return null;
            }

        },
        isLogoSelected(ele) {
            const elements = Object.values(ele);
            const eleClasses = elements.length > 0 ? elements[0].element_classes : null;
            if(!eleClasses) return false
            return eleClasses.find(str => str.includes('logo'));
        },
        getStatesByName(stateName) {
            return this.states[stateName]
        },
        /**
         * Sorts an array of items (projects/templates) based on the priorityTemplateDimensions
         *
         * @param {Array} items - The list of items to be sorted
         * @returns {Array} The sorted list of items
         */
        sortByPriorityDimensions(itemList) {
            const priorities = this.priorityTemplateDimensions;

            if (!itemList.length) return;

            return itemList.sort((a, b) => {
                const isTopPriority = (w, h) => priorities.some(p => p.width === w && p.height === h);

                const aIsTopPriority = isTopPriority(a.dimensions?.width, a.dimensions?.height);
                const bIsTopPriority = isTopPriority(b.dimensions?.width, b.dimensions?.height);

                if (aIsTopPriority && !bIsTopPriority) {
                    return -1;
                }
                else if (!aIsTopPriority && bIsTopPriority) {
                    return 1;
                }
                // If both are top priority or neither is, sort based on priority order
                const aPriority = priorities.findIndex(p => p.width === a.dimensions?.width && p.height === a.dimensions?.height);
                const bPriority = priorities.findIndex(p => p.width === b.dimensions?.width && p.height === b.dimensions?.height);

                if (aPriority !== -1 && bPriority !== -1) {
                    return aPriority - bPriority;
                }

                // If neither matches priorityTemplateDimensions, compare normally
                // Use template_title if available, otherwise fall back to project_id or id, using "ZZZ" prefix to
                // weight them towards the end of the list
                const aTitle = a.template_title || "ZZZ" + (a.project_id || a.id || 0);
                const bTitle = b.template_title || "ZZZ" + (b.project_id || b.id || 0);

                return aTitle.localeCompare(bTitle);
            });
        },
        /**
         * Updates the store's ad.templates with all variations (different sizes) of the same template design
         * Note: Function name is restricted as AdThemeModal (shared component) calls the same named function in AdEditStore.
         *
         * @param {Object} template
         *     The user-selected template object
         *
         * @description
         * This function takes the user-selected template, extracts its design identifier,
         * finds all variations (different sizes) of the same template design, and assigns them to the store's ad.template.
         * This allows for consistent handling of related template variations across the application.
         *
         */
        updateParentTheme(selectedTemplate) {
            // Extract the template group identifier
            const templateGroup = selectedTemplate.templateGroup;

            // Find related templates in either standard or custom lists
            const relatedTemplates = this.getGroupedTemplateLists[templateGroup] || this.getGroupedCustomTemplateLists[templateGroup] || [];

            // Assign all related templates to the store's ad.template
            this.ad.templates = relatedTemplates;

            this.prioritizeSelectedTemplate()
        },
        /**
         * Checks if all essential classes are present in the given data (project elements).
         * This function verifies that the data contains all required classes
         * for proper rendering and functionality of the project.
         *
         * It processes the entire data structure, flattening all element classes
         * and comparing them against the predefined essential classes.
         *
         * @param {Object|Array<Object>} data - The data object or array containing all elements. (e.g. editorData.scenes[0].elements)
         * @returns {void} Does not return anything, but logs warnings if essential classes are missing.
         */
        checkEssentialClassesInData(data) {
            const allElementClasses = Object.values(data).flatMap(element => element.element_classes);

            const missingEssentialClasses = this.essentialClasses.filter(
                essentialClass => !allElementClasses.includes(essentialClass)
            );

            if (missingEssentialClasses.length > 0) {
                console.warn(`Warning: Essential classes missing in the project. Missing classes: ${missingEssentialClasses.join(', ')}`);
            }
        },
        /**
         * Safely accesses nested values within an object.
         * This function traverses the object structure along a given path,
         * returning the value at the end of the path if it exists, or undefined otherwise.
         *
         * It handles cases where intermediate properties might be undefined,
         * preventing errors that could occur with direct property access.
         *
         * @param {Object} obj - The root object to start traversing from.
         * @param {String} path - The path to the desired value, using dot notation or bracket notation.
         * @returns {*} The value at the specified path, or undefined if the path is invalid.
         */
        safeAccessNestedValue(obj, path) {
            const keys = path.replace(/\[/g, '.').replace(/]/g, '').split('.');
            return keys.reduce((o, k) => o == null ? undefined : o[k], obj);
        },
        /**
         * Functions for LogoModal and LogoCard
         *
         * Since the component was developed using the previous store implementation,
         * these methods are designed to align with the functions within those components,
         * enabling us to reuse them with the new Pinia store without requiring any modifications to the components themselves.
         *
         */
        getLogo() {
            return this.ad.logo
        },
        updateLogoDataUrl(dataUrl) {
            // dataUrl is in base64
            this.ad.logo.dataUrl = dataUrl
        },
        updateLogo(logo) {
            if (!logo) return
            this.ad.logo = {...logo}
            if(!isEmpty(logo.palette)) {
                this.updateFullColourPalette(logo.palette)
            }
        },
        /**
         * Organize template lists into groups based on their template titles
         * This method works for both standard and custom templates.
         * (Name format: the_template_name__size_1)
         *
         * @param {Array} list - The list of templates to be grouped (either standard or custom)
         * @returns {object} An object containing grouped template lists
         * @example
         * const groupedStandardTemplates = store.getGroupedTemplateLists;
         *
         * // Example return value:
         * {
         *   "template_name_1": [
         *     { template_id: 1, template_title: "template_name_1__size_1", dimensions: { width: 1920, height: 1080 } },
         *     { template_id: 2, template_title: "template_name_1__size_2", dimensions: { width: 1080, height: 1920 } }
         *   ],
         *   "template_name_2": [
         *     { template_id: 3, template_title: "template_name_2__size_1", dimensions: { width: 1080, height: 1080 } },
         *     { template_id: 4, template_title: "template_name_2__size_2", dimensions: { width: 1920, height: 1080 } }
         *   ]
         * }
         */
        groupTemplateList (list) {
            const groupedObjects = {};

            list.forEach(obj => {
                const extractedTemplateName = obj.template_title.split('__')[0];
                obj.templateGroup = extractedTemplateName;
                obj.id = JSON.stringify(obj.template_id);

                if (!groupedObjects[extractedTemplateName]) {
                    groupedObjects[extractedTemplateName] = [];
                }

                groupedObjects[extractedTemplateName].push(obj);
            });

            Object.values(groupedObjects).forEach(group => {
                group.sort((a, b) => {
                    const aPriority = this.priorityTemplateDimensions.findIndex(p => p.width === a.dimensions.width && p.height === a.dimensions.height);
                    const bPriority = this.priorityTemplateDimensions.findIndex(p => p.width === b.dimensions.width && p.height === b.dimensions.height);
                    return aPriority - bPriority;
                });
            });

            return groupedObjects;
        },
    },
    getters: {
        getDefaultThemeForItemType: (state) => {
            const itemType = state.advertiserItemType
            return state.itemTypeToDefaultThemeMap[itemType] || null;
        },
        getCurrentItem: (state) => {
            return state.ad.itemList[state.ad.currentItemIndex]
        },
        getCustomTemplateBrandName: (state) => {
            return state.env + '_' + state.state.websiteId
        },
        getColourPalette: (state) => {
            return state.colourPalette
        },
        getIsAllItemsChecked: (state) => {
            return state.ad.itemList.every(item => 
                'accessibility' in item
            );
        },
        getHasAccessibleItem: (state) => {
            return state.ad.itemList.some(item => 
                'accessibility' in item && item.accessibility === true
            );
        },        
        getProjectsForSaving: (state) => {
            return state.ad.projects.map(project => ({
                ...project,
                dimensions: {
                    width: project.dimensions.width,
                    height: project.dimensions.height
                },
                template_name: state.ad.templates.find(template => template.template_id === project.template_id)?.templateGroup || ""
            }))
        },
        getGroupedTemplateLists: (state) => {
            return state.groupTemplateList(state.templateList);
        },
        getGroupedCustomTemplateLists: (state) => {
            return state.groupTemplateList(state.customTemplateList);
        },
        getTemplatesAndItemsReady: (state) => {
            return state.states.isItemsLoaded && state.getIsAllTemplatesLoaded
        },
        getIsAllTemplatesLoaded: (state) => {
            return state.states.isTemplateLoaded && state.states.isCustomTemplateLoaded
        },
        // For display size selection
        getProjectsSizes: (state) => {
            return state.ad.projects.map(project => ({
                value: project.project_id,
                text: `${project.dimensions.width}px x ${project.dimensions.height}px`
            }));
        },
        getSelectedSizeDisplayValueByProjectId: (state) => (projectId) => {
            const projectSizes = state.getProjectsSizes;
            const selectedProject = projectSizes.find(size => size.value === projectId);
            return selectedProject ? selectedProject.text : null;
          },
        /**
         * Returns an object containing elements that have corresponding color palette names as class names.
         * This function is used to prepare data for sending to DH's updateElements method.
         *
         * It searches through the editor data, comparing element classes with the available color palette entries.
         * Elements that have classes matching the color palette keys are included in the returned object.
         *
         * @returns {Object} An object where:
         *                  - Keys are unique element identifiers (strings)
         *                  - Values are objects containing a single property:
         *                    - "color": A string representing the hex color code (#RRGGBB) from the logo's palette
         * @example
         * {
         *     "elementId1": { "color": "#FFFF00" },
         *     "elementId2": { "color": "#0000ff" }
         * }
         */
        getElementsMatchingColourPalette: (state) => {
            const { ad, colourPalette} = state
            const editorData = ad.editorData;

            if (!editorData || !editorData.scenes) {
                const missingData = [];
                if (!editorData) missingData.push('editorData');
                if (!editorData?.scenes) missingData.push('editorData.scenes');
                if (!colourPalette) missingData.push('colourPalette');

                console.warn(`Missing required data for palette-based element extraction: ${missingData.join(', ')}`);
                return [];
            }
            const result = {};

            // Convert scenes object to an array
            const scenesArray = Object.values(ad.editorData.scenes);

            Object.values(scenesArray).forEach(scene => {
                Object.entries(scene.elements).forEach(([elementId, element]) => {
                    const elementClasses = Array.isArray(element.element_classes) ? element.element_classes : [element.element_classes];

                    elementClasses.forEach(className => {
                        if (colourPalette[className]) {
                            if (!result[elementId]) {
                                result[elementId] = {};
                            }
                            result[elementId].color = colourPalette[className];
                        }
                    });
                });
            });
            return result
        },
        imageElementValues: (state) => {
            const imageUrl = state.getCurrentItem?.thumbnail_urls[0];

            return imageUrl != null ? {image: { propertyName: "url", value: imageUrl}} : {}
        },
        logoElementValues: (state) => {
            const { safeAccessNestedValue } = state;

            const value = safeAccessNestedValue(state.ad, 'logo.dataUrl') ??
                safeAccessNestedValue(state.ad, 'logo.asset');

            if (value == null) {
                console.warn('Neither logo.dataUrl nor logo.asset is available');
            }

            return value != null ? {logo: {propertyName: 'url', value}} : {}
        },
        paletteElementValues: (state) => {
            const { safeAccessNestedValue } = state;
            return {
                primary_text: {
                    propertyName: 'color',
                    value: safeAccessNestedValue(state.ad.logo, 'palette.primary_text')
                },
                secondary_text: {
                    propertyName: 'color',
                    value: safeAccessNestedValue(state.ad.logo, 'palette.secondary_text')
                },
                tertiary_text: {
                    propertyName: 'color',
                    value: safeAccessNestedValue(state.ad.logo, 'palette.tertiary_text')
                },
                primary: {
                    propertyName: 'color',
                    value: safeAccessNestedValue(state.ad.logo, 'palette.primary')
                },
                secondary: {
                    propertyName: 'color',
                    value: safeAccessNestedValue(state.ad.logo, 'palette.secondary')
                },
                tertiary: {
                    propertyName: 'color',
                    value: safeAccessNestedValue(state.ad.logo, 'palette.tertiary')
                }
            }
        },
        /**
         * Generates a Project Update Object for updating elements when switching to a different size.
         * This function uses the current editor data and default non-color values to create an object
         * suitable for updating Design Huddle elements.
         *
         * @returns {Object} A formatted object ready to be used with the Design Huddle API's updateElements method.
         * @example
         * {
         *   "elementId1": {
         *     "text": "Updated Text",
         *     "url": "https://example.com/image.jpg"
         *   },
         *   "elementId2": {
         *     "fontSize": 16,
         *     "opacity": 0.8
         *   }
         * }
         */
        getDefaultSwitchSizeElementValues(){
            const editorData = this.ad.editorData;
            const nonColorDefaults = {
                ...this.imageElementValues,
                ...this.logoElementValues,
                ...this.itemElementValues
            };
            if (!editorData || !editorData.scenes || !nonColorDefaults) {
                console.warn("Missing required data for generating switch size element values.");
                return {};
            }
            return nonColorDefaults
        },
        /**
         * Retrieves and formats item-related elements with values from the current item.
         *
         * It searches through the editor data, identifying elements that correspond to item properties
         * (such as name, description, price, etc.) and updates them with values from the current item.
         * The function returns an object containing the formatted elements.
         *
         * @returns {Object} An object where:
         *                  - Keys are unique element identifiers (strings)
         *                  - Values are objects containing properties:
         *                    - "propertyName": A string representing the property name to update in the element
         *                    - "value": The value from the current item to be applied to the element
         * @example
         * {
         *     "name": { "propertyName": "text", "value": "Product X" },
         *     "description": { "propertyName": "text", "value": "Description of Product X." },
         *     "price_whole": { "propertyName": "text", "value": "99" },
         *     "price_fraction": { "propertyName": "text", "value": ".99" }
         * }
         */
        getFormattedItemElements(state) {
            if (!state.states.isEditorReady) return

            const { itemFields, formatFieldValue, itemElementValues } = state;

            return Object.fromEntries(itemFields.map(className => {
                const value = itemElementValues[className];
                if (value?.value === null || value?.value === undefined) {
                    console.log(`Skipping element '${className}' because its default value is null/undefined`);
                    return [undefined, undefined]
                }

                return [
                    className,
                    {propertyName: value.propertyName, value: formatFieldValue(className, value.value)}]

            }).filter(([key, value]) => key !== undefined && value.value !== undefined && value.value !== null));
        },
        convertMappingFromClassnameToElementId(state) {
            /**
             * Utility method to convert a mapping of updates from a classname-based spec to an element-ID spec usable
             * by the DH JS SDK's updateElements method.
             *
             * Example input: {
             *     "name": {propertyName: "text", value: "Product A"},
             *     "primary": {propertyName: "color", value: "#ff0000"}
             * }
             *
             * Example output: {
             *     "element-xyz": {
             *         text: "Product A",
             *         color: "#ff0000"
             *     }
             * }
             */
            return (updatesByClassName) => {
                const updatesByElementId = {};
                //const classNamesIn = Object.keys(updatesByClassname);
                // Process each element in the scene
                Object.entries(state.ad.editorData.scenes).forEach(([sceneId, scene]) => {

                    this.checkEssentialClassesInData(scene.elements);

                    Object.entries(scene.elements).forEach(([elementId, element]) => {
                        // Initialize an object to store the attributes for this element
                        const attributes = {};
                        // Check if the element has any classes
                        if (element.element_classes && element.element_classes.length > 0) {
                            // Process each class associated with the element
                            element.element_classes.forEach(className => {
                                if (!updatesByClassName[className] && className !== "dynamic") return;
                                if (className === "dynamic" && element.type === "lottie") {
                                    const layerUpdates = this.getLottieLayerUpdates(element, updatesByClassName);
                                    if (!isEmpty(layerUpdates)) {
                                        attributes["layers"] = attributes["layers"] || {};
                                        merge(attributes["layers"], layerUpdates)
                                    }
                                }
                                else {
                                    const updates = updatesByClassName[className];
                                    attributes[updates.propertyName] = updates.value;
                                }
                            });
                            // Add the attributes to the formatted object
                            if (Object.keys(attributes).length > 0) {
                                updatesByElementId[elementId] = attributes;
                            }
                        }
                        if (!isEmpty(attributes)) {
                            updatesByElementId[elementId] = attributes;
                        }
                    });
                });
                return updatesByElementId;
            }
        },
        getLottieLayerUpdates(state) {
            /**
             * Utility function to translate updates mapped by class name to layer-based updates for a Lottie element.
             *
             * Logic is based on a naming convention for Lottie animation layers where dynamic layers are named after a
             * double-underscore-separated list of class names that the layer wants to take updates for.
             *
             * @param element {Object} Lottie animation element
             * @param updatesByClassName {Object} Mapping from class name to element updates
             * @returns {Object} Mapping of Lottie layer keys to ProjectUpdateObject-style updates
             */
            return (element, updatesByClassName) => {
                const layerUpdates = {};
                Object.entries(element.layers).forEach(([layerKey, layer]) => {
                    let layerClassNames = layerKey
                        // Strip of .Fill 1 suffixes for layers (added during Bodymovin Lottie export
                        .replace(stripDotSuffixRegex, "$1")
                        // Separate class/field names with double-underscore convention
                        .split("__");
                    Object.entries(updatesByClassName).forEach(([className, updates]) => {
                        if (layerClassNames.includes(className)) {
                            layerUpdates[layerKey] = (layerUpdates[layerKey] || {});
                            layerUpdates[layerKey][updates.propertyName] = updates.value;
                        }
                    })
                });
                return layerUpdates;
            }
        },
        getLottieLayerNamesByElementID(state) {
            return (updatesByElementID) => {
                const layerNamesByElementID = {};

                // Iterate over each element in the input data
                Object.entries(updatesByElementID).forEach(([elementId, updates]) => {
                    // Check if the element has a 'layers' property that's an object
                    if (updates.layers && typeof updates.layers === 'object') {
                        // Get the names of all layers by taking the keys from the 'layers' object
                        layerNamesByElementID[elementId] = Object.keys(updates.layers);
                    }
                });

                return layerNamesByElementID;
            }
        },
        /**
         * Provides default values for item-related elements.
         *
         * It retrieves default values from the store's state, including the current item.
         * The function returns an object containing the default values for various item-related elements.
         *
         * @returns {Object} An object where:
         *                  - Keys are element classes
         *                  - Values are objects containing properties:
         *                    - "propertyName": A string representing the property name to update in the element
         *                    - "value": The default value to be applied to the element
         * @example
         * {
         *     "name": { "propertyName": "text", "value": "Default Product Name" },
         *     "description": { "propertyName": "text", "value": "Default Description" },
         *     "price_whole": { "propertyName": "text", "value": "0" },
         *     "price_fraction": { "propertyName": "text", "value": ".00" }
         * }
         */
        itemElementValues(){
            const currentItem = this.getCurrentItem;
            if (!currentItem) return;

            return Object.fromEntries(
                this.itemFields.map(field =>
                    currentItem[field] !== undefined ?
                    [
                        field,
                        {
                            propertyName: "text",
                            value: this.formatFieldValue(field, currentItem[field])
                        }
                    ]
                    : undefined
                ).filter(item => item !== undefined)
            );
        },
        formatFieldValue: () => {
            return (fieldName, value) => {
                if (fieldName === "baths" || fieldName === "beds") {
                    let floatVal = parseFloat(value);
                    return Number.isInteger(floatVal) ? Math.round(floatVal) : Math.round(floatVal * 10) / 10
                }
                // Design Huddle editor wants \r for a line break
                if (typeof value === "string") {
                  value = value.replaceAll("\n", "\r");
                }
                return value;
            }
        },
        toTCO: (state) => {
            /**
             * Utility function to translate a ProjectUpdateObject-style object to TCO style
             * @projectElementsObject {Object} Mapping from class name to ProjectUpdateObject-style updates
             */
            return (projectElementsObject) => {
                let tco = {};
                Object.keys(projectElementsObject).forEach(key => {
                    const eleValue = projectElementsObject[key].value;
                    // Only add to transformedDefaultValuesToTCO if eleValue is not null or undefined,
                    // normally the eleValue will become null or undefined while no logo / logo palette is not available
                    if (eleValue !== null && eleValue !== undefined) {
                        tco[key] = {
                            [projectElementsObject[key].propertyName]: eleValue ? eleValue.toString() : eleValue === 0 ? '0' : ''
                        };
                    }
                });
                return tco;
            }
        },
        /**
         * Determines whether template previews should be regenerated.
         *
         * This getter checks two conditions:
         * 1. If all template previews are using the current item's ID.
         * 2. If all templates have been generated (vtp_generated is true).
         *
         * It returns true if either condition is not met, indicating that previews should be regenerated.
         *
         * @returns {boolean} True if template previews should be regenerated, false otherwise.
         * @example
         * if (store.shouldRegenerateTemplatePreviews) {
         *   // Regenerate template previews
         * }
         */
        shouldRegenerateTemplatePreviews: (state) => {
            const currentItem = state.getCurrentItem
            if (!currentItem) return true // If no current item, we should regenerate
            if(!state.getTemplatesAndItemsReady) return false

            const allTemplates = state.getGroupedTemplateLists

            // Check if all template groups have the same vtp_item_id
            const allSame = Object.values(allTemplates).every(templateGroup => {
                if (!templateGroup[0].vtp_generated) return false
                return templateGroup[0].vtp_item_id === currentItem.id
            })

            // Check if all templates have vtp_generated set to true
            const isAllTemplatesHasVTP = Object.values(allTemplates).every(templateGroup => {
                return templateGroup[0].vtp_generated === true;
            })

            return !allSame || !isAllTemplatesHasVTP
        },
        /**
         * Compares the current colour palette with the default colours from the logo.
         * This function determines whether the active colour palette differs from the default colours provided by the logo.
         *
         * It checks each colour in the current palette against the corresponding colour in the logo's palette.
         * If any colour mismatch is detected, the function returns true, indicating that the colour palette is different from the logo's default.
         *
         * @returns {Boolean} True if the colour palette is different from the logo's default, false otherwise.
         * @example
         * const isDifferent = store.isColourPaletteDifferentFromLogoDefault;
         * if (isDifferent) {
         *   console.log("The current colour palette has been modified from the logo's defaults.");
         * }
         */
        isColourPaletteDifferentFromLogoDefault: (state) => {
            if(!state.ad.logo) return true
            if(!state.ad.logo.palette) return true // Handle if a logo do not have default_colour_palette

            const { colourPalette, ad } = state

            for (const key in colourPalette) {
                // Turn value to upper case to prevent result true when compare colour code '#FFFFFF' vs '#ffffff'
                if(colourPalette[key]?.toUpperCase() !== ad.logo.palette[key]?.toUpperCase()){
                    return true
                }
            }
            return false
        },
    },
});
