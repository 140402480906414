// Components
import { LogoModal } from "./logos/LogoModal.js";

// Utility functions
import { debounce, isEmpty } from "lodash";

// Pinia store and utilities
import { useDHVideoAdEditStore } from "./store/DHVideoAdEditStore.js";
import { storeToRefs } from 'pinia';

import { DesignHuddleService } from './common/api.service.js';

/**
 * DHVideoAdEditor Component
 * 
 * Implements the core Design Huddle editor functionality.
 * Handles template manipulation and rendering within the Design Huddle framework.
 * Manages project creation, loading, and updating based on user interactions.
 *
 */
const DHVideoAdEditor = {
    name: "DHVideoAdEditor",
    data: function () {
        return {
            dhEditor: null,
            store: storeToRefs(useDHVideoAdEditStore()),
            editorContainerId : "dh-container",
        }
    },
    props: {
        website: String,
        preset_image_thumbnail: String
    },
    computed: {
    },
    methods: {
        createProjects() {
            this.store.updateState('isLoading', { status: true, message: this.store.loadingMessages.creatingProject, isShowSpinner: true });

            // Clear ad.projects in store before create new projects
            if(this.store.ad.projects.length > 0) this.store.clearAdProjects();

            const selectedTemplates = this.store.ad.templates;  // Contain all sizes
            if(!selectedTemplates || selectedTemplates.length === 0) return;
        
            const projectData = [];
            const additionalData = {};
            selectedTemplates.forEach(template => {
                const templateId = template.template_id;
                const mergedCustomizations = {
                    classes: {
                        ...this.store.toTCO({
                            image: {
                                propertyName: 'url',
                                value: this.store.getCurrentItem?.thumbnail_urls[0]
                            },
                            ...this.store.itemElementValues,
                            ...this.store.paletteElementValues
                        })
                    }
                };

                projectData.push({
                    template_id: templateId,
                    customizations: mergedCustomizations
                });
                additionalData[templateId] = {
                    dimensions: template.dimensions,
                    duration: template.duration
                };
            });

            DesignHuddleService.createProjects(projectData).then((response) => {
                this.store.addAdProjects(response.data.projects);
                this.store.ad.projects.forEach((project) => {
                    if (additionalData[project.template_id]) {
                        this.store.updateProjectData(project.project_id, additionalData[project.template_id]);
                    }
                });
                this.store.updateItemFields(response.data.fields);
            });
        
            // Set to false to trigger update default value
            this.store.updateState('isUpdatedNewProjects', false)
        },
        loadProject(){
            if(this.dhEditor) this.dhEditor.remove();

            // Take the first project to load 
            const projectId = this.store.ad.projects[0].project_id
            this.store.updateSelectedSize(this.store.ad.projects[0].project_id)

            this.$designHuddle.Editors.insert(this.editorContainerId, { 
                project_id: projectId
            }, function(err, e){
                this.dhEditor = e
                console.log("loadProject error", err)
                this.getProjectData()
                this.setUpHandleSelectionChange()
                this.setUpHandleProjectDataModification()
                this.setUpHandleUnauthenticated()
                this.setUpHandleEditorError()

                this.store.updateState('isLoading', { status: false, message: null, isShowSpinner: null });
            }.bind(this));
            
        },
        // Get elements of the displaying project
        getProjectData() {
            if(!this.dhEditor) return

            this.dhEditor.getProjectData({}, function(err, project){
                console.log("Project data:", project);
                this.store.updateEditorData(project)
                this.store.updateState('isEditorReady', true)

                if(this.store.states.isSwitchingSize) 
                {
                    this.handleSwitchingSize()
                }
            }.bind(this));
        },

        setUpHandleEditorError() {
            this.dhEditor.handleError(function(e){
                console.log("Editor error occurred:", e);
            });
        },

        async handleSwitchingSize() {
            if (!this.store.states.isSwitchingSize) return;
            await this.updateProjectElements(this.store.getDefaultSwitchSizeElementValues);
        },

        checkFileAccessibility(imageUrl) {
            return new Promise((resolve) => {
                const newImageUrl = new URL(imageUrl);
                newImageUrl.searchParams.append('t', new Date().getTime());
                var http = new XMLHttpRequest();
                http.open('HEAD', newImageUrl.toString(), false);
                http.send();
                
                console.log(`File accessibility check for ${imageUrl}: HTTP status = ${http.status}`);
                
                resolve(http.status === 200);
            });
        },

        handleItemAccessibilityCheck(callback) {
            return new Promise((resolve) => {
                const currentItemIndex = this.store.ad.currentItemIndex;
                const currentItem = this.store.ad.itemList[currentItemIndex];
        
                if (!currentItem) {
                    console.log("No more items to check");
                    resolve(null);
                    return;
                }
        
                if ('accessibility' in currentItem) {
                    if (currentItem.accessibility) {
                        resolve(callback(currentItem));
                    } else {
                        this.handleSwitchItem()
                        resolve();
                    }
                } else {
                    console.log("Item hasn't been checked yet, performing accessibility check");
                    const currentItemThumbnailUrl = currentItem.thumbnail_urls[0];
                    this.checkFileAccessibility(currentItemThumbnailUrl).then(isFileReachable => {
                        // Update the accessibility status in the store
                        this.store.updateCurrentItemAccessibility(isFileReachable);
        
                        if (isFileReachable) {
                            resolve(callback(currentItem));
                        } else {
                            this.handleSwitchItem()
                            resolve();
                        }
                    });
                }
            });
        },
        handleSwitchItem() {
            // Check conditions and switch to next item if necessary
            if (!this.store.getIsAllItemsChecked || (this.store.getIsAllItemsChecked && this.store.getHasAccessibleItem)) {
                console.log("Item is not accessible, moving to next item");
                this.store.switchToNextItem()
            }else{
                console.warn("Warning: Unable to switch to next item. All items have been checked, but no accessible items found.");
            }
        },
        /**
         * Update DH editor elements.
         * @param updatesByClassname {Object} Mapping from classes to our internal property/value representation, e.g.
         *      {propertyName: "text", value: "Shoes"}
         */
        updateProjectElements(updatesByClassname) {

            console.log("updateProjectElements", updatesByClassname);
            if(!this.dhEditor || isEmpty(updatesByClassname)) return;

            const elementUpdates = this.store.convertMappingFromClassnameToElementId(updatesByClassname);

            if (!isEmpty(elementUpdates)) {
                this.dhEditor.updateElements({elements: elementUpdates});
            }

            // Reset when update element finished
            if(this.store.states.isSwitchingSize) this.store.updateState('isSwitchingSize', false);
            this.store.updateState('isLoading', false);
            this.store.updateLottieElementLayers(this.store.getLottieLayerNamesByElementID(elementUpdates));
        },
        updateLogo() {
            const logoElements = this.store.getElementsByClassName('logo');
            if(!logoElements) {
                console.warn("The element with the class `logo` does not exist. Cannot update logo.");
                return
            }

            this.updateProjectElements({"logo": {propertyName: "url", value: this.store.ad.logo?.asset}})
        },
        async updateItemDisplayDetails() {
            if(!this.store.getStatesByName("isEditorReady")) return;
            const imageUpdates = {};
            await this.handleItemAccessibilityCheck((item) => {
                imageUpdates["image"] = {propertyName: "url", value: item.thumbnail_urls[0]};
                return item;
            });
            this.updateProjectElements({
                ...imageUpdates,
                ...this.store.getFormattedItemElements
            });
        },
        setUpHandleSelectionChange() {
            if(!this.dhEditor) return
            this.dhEditor.handleSelectionChange(function(e, elements){
                console.log("Selected Element(s): ", elements);
                if(this.store.isLogoSelected(elements)) {
                    this.showBrandSection()
                }else{
                    this.hideBrandSection()
                }
            }.bind(this));
        },
        // Sync our store's project data with the actual editor's project data when the project data is updated in the DH editor.
        setUpHandleProjectDataModification(){
            if(!this.dhEditor) return
            this.dhEditor.handleProjectDataModification(function(e, updates){
                console.log("Pages/Scenes Altered:", Object.keys(updates.pages || updates.scenes).length);
                this.getProjectData()
            }.bind(this));
        },
        setUpHandleUnauthenticated() {
            if(!this.dhEditor) return
            this.dhEditor.handleUnauthenticated(function(e){
                this.$emit('forceRefreshAccessToken')
            }.bind(this));
        },
        switchProject(newProjectId) {
            this.store.states.isSwitchingSize = true
            this.store.updateState('isSwitchingSize', true)
            this.store.updateState('isEditorReady', false)
            this.dhEditor.changeProject(newProjectId);
            this.getProjectData()
        },
        showBrandSection() {
            this.store.updateState("isShowBrandSection", true)
        },
        hideBrandSection() {
            this.store.updateState("isShowBrandSection", false)
        },
        handleColourPaletteChanges() {
            if(!this.dhEditor) return;
            if(!this.store.ad.logo) return;
            if(!this.store.ad.logo.palette) return;
            this.applyColourPaletteToEditor();
        },
        applyColourPaletteToEditor() {
            if(!this.dhEditor) return;

            const colourPaletteClassNames = Object.keys(this.store.getColourPalette);
            const logo = this.store.getLogo();

            this.updateProjectElements(
                Object.fromEntries(colourPaletteClassNames.map(className => {
                    if (logo.palette[className] !== this.store.getColourPalette[className])
                        return [className, {propertyName: "color", value: this.store.getColourPalette[className]}];
                    return [undefined, undefined];
                }).filter(([key, value]) => {
                    return key !== undefined
                }))
            )
        },
        refreshEditorToken(token) {
            if (!this.dhEditor) return
            this.dhEditor.refreshAccessToken(token);
        }
    },
    watch: {
        'store.ad.templates'() {
            // Create projects only when not in edit mode; however, allow project creation during template switching
            // when there are existing projects (i.e., ad.projects.length is greater than 0).
            if (!(this.store.states.isEditMode && this.store.ad.projects.length === 0)) {
                this.createProjects();
            }
        },
        'store.ad.projects'() {
            // Because of the way this watch is set up, we should set projects in the store all at once.
            // It seems that if we set & sort in the same event loop, this watch will only get triggered once.
            if(this.store.ad.projects.length > 0) {
                this.store.updateState('isCreateProjectDone', true);
                this.loadProject()
            }
        },
        'store.ad.selectedSizeProjectId'(newValue) {
            this.switchProject(newValue)
        },
        'store.ad.logo': {
            handler: (function (newSelected) {
                if(newSelected && this.store.states.isCreateProjectDone) this.updateLogo()
            }),
            deep: true
        },
        'store.ad.currentItemIndex': {
            handler: debounce(function () {
                this.updateItemDisplayDetails()
            }, 500),
            deep: true
        },
        'store.ad.itemList': {
            handler: (function () {
                this.updateItemDisplayDetails()
            }),
            deep: true
        },
        'store.colourPalette': {
            handler: debounce(function () {
                this.handleColourPaletteChanges()
            }, 2000),
            deep: true
        },
        'store.dhAccessToken' (newValue) {
            this.refreshEditorToken(newValue)
        }
    },
    components: {
        LogoModal,
    },
    template:
    `
        <div class="dh-video-ad-preview row h-100 w-100 no-gutters">
            <div id="dh-container" class="w-100"></div>
        </div>
    `
    ,
}

export { DHVideoAdEditor };
