import ApiService from '../common/api.service.js'
import { ModalReportDetails } from './ModalReportDetails.js'
import Vue from 'vue'
import { usePermissionsStore } from '../store/PermissionStore.js';
import { mapState } from 'pinia';

let ScrapeData = {
    name: "ScrapeData",
    props: {
        name: String,
        import_type: String,
        updateURL: String,
        returnURL: String,
        staticUrl: String,
        accountId: String,
        formatFieldName: {
            type: Function,
            default: (value) => value // Safe default
        }
    },
    components: {
        'report-modal': ModalReportDetails
    },
    created: async function () {
        await this.getScrapeData()
    },

    methods: {
        async getScrapeData(timeout=2000) {
            let params = {
                expand: 'datasource_params,data_import',
                only: 'datasource_params,name,data_import'
            }
            const response = await ApiService.query(`websites/${this.name}`, params)
            if (response.data_import) {
                this.import_not_found = false

                if (response.data_import.import_end_time === null) {
                    setTimeout(this.getScrapeData, timeout, timeout+2000)
                }

                this.import_end_time = response.data_import.import_end_time
                this.$emit('updateDisplayOfEditBtn', response.data_import.import_end_time === null)
                this.summary = response.data_import.latest_import_summary
                this.system_errors = response.data_import.latest_import_system_errors
                this.errors = response.data_import.latest_import_errors
            }
            this.datasource_params = response.datasource_params
        },

        requestUpdate() {
            Vue.axios({
                method: 'post',
                url: this.updateURL,
            }).then(response => {
                window.location.href = this.returnURL;
            }).catch(error => {
                this.ajax = false;
            });
        },
        handleModal() {
            this.$bvModal.show('modal-report-details' + this.modalKey)
        },
    },
    computed: {
        ...mapState(usePermissionsStore, ['permissions']),
        noImportFound: function () {
            return this.import_not_found
        },
        importFinished: function () {
            return this.import_end_time != null
        },
        hasImportIssue: function () {
            return this.hasErrors || this.hasWarnings
        },
        hasErrors: function () {
            return this.errors.errors.count > 0
        },
        hasWarnings: function () {
            return this.errors.warnings.count > 0
        },
        numberOfErrorsOrSystemError: function () {
            return this.errors.errors.count > 1 ? `${this.errors.errors.count} Errors` : `${this.errors.errors.count} Error`
        },
        numberOfWarnings: function () {
            return this.errors.warnings.count > 1 ? `${this.errors.warnings.count} Warnings` : `${this.errors.warnings.count} Warnings`
        }
    },
    data: function () {
        return ({
            import_not_found: true,
            datasource_params: {},
            import_end_time: null,
            system_errors: null,
            errors: {
                errors: {count: 0, column_names: [], issues: []},
                warnings: {count: 0, column_names: [], issues: []}
            },
            summary: {},
            modalKey: '-scrape',
            permissionsStore : usePermissionsStore(),
        })
    },
    template:
        `
          <div id="scrape">
            <div v-if="system_errors">
                <div class="alert alert-danger default-theme-border-radius" role="alert">
                  <h5>Import Failed</h5>
                    <p class="mb-2">{{ system_errors }}</p>
                    <p>Try again or <a href="/contact_us/" target=”_blank”>contact support</a> for assistance.</p>
                    <button
                        class="btn btn-primary"
                        v-if="datasource_params && datasource_params.is_configured && datasource_params.can_schedule"
                        @click="requestUpdate()">
                        Retry
                    </button>
                </div>
            </div>
            <div v-else>
              <div class="border mb-3 p-3 bg-white default-theme-border-radius">
                <div class="border-bottom">
                  <h5>Status</h5>
                </div>
                <table class="table table-borderless">
                  <tbody>
                  <tr v-if="noImportFound" style="height: 125px">
                    <td colspan="2" class="loading"></td>
                  </tr>
                  <tr v-else-if="!importFinished">
                    <td colspan="2" class="text-center">Importing data...</td>
                  </tr>
                  <tr v-if="!hasImportIssue && importFinished" class="row mr-auto align-items-center">
                    <td><font-awesome-icon class="text-success" :icon="['fas', 'check-circle']" />
                      All good!
                    </td>
                  </tr>
                  <tr v-else-if="hasImportIssue && importFinished" class="row mr-auto">
                    <td><span v-if="hasErrors" @click="handleModal()"><font-awesome-icon class="text-danger" :icon="['fas', 'exclamation-circle']" />
                                          <span class="pseudolink">{{ numberOfErrorsOrSystemError }}</span></span>
                      <span v-if="hasWarnings" @click="handleModal()"><font-awesome-icon class="text-warning" :icon="['fas', 'exclamation-triangle']" />
                                          <span class="pseudolink">{{ numberOfWarnings }}</span></span></td>
                  </tr>
                  <div v-if="importFinished">
                    <tr class="row mr-auto">
                        <th class="col-sm-3">Last Update:</th>
                        <td>{{ summary.last_update }}</td>
                    </tr>
                    <tr class="row mr-auto">
                        <th class="col-sm-3">Data Source:</th>
                        <td>{{ formatFieldName(import_type) }}</td>
                    </tr>
                    <tr class="row mr-auto">
                        <th class="col-sm-3">Total:</th>
                        <td>{{ summary.total_count}}</td>
                    </tr>
                    <tr class="row mr-auto">
                        <th class="col-sm-3">New:</th>
                        <td>{{ summary.new_count}}</td>
                    </tr>
                    <tr class="row mr-auto">
                        <th class="col-sm-3">Updated:</th>
                        <td>{{ summary.updated_count}}</td>
                    </tr>
                    <tr class="row mr-auto">
                        <th class="col-sm-3">Removed:</th>
                        <td>{{ summary.removed_count}}</td>
                    </tr>
                  </div>
                  </tbody>
                </table>
                <component
                    :is="'report-modal'"
                    :modalKey="modalKey"
                    :hasErrors="hasErrors"
                    :hasWarnings="hasWarnings"
                    :errors="errors.errors"
                    :warnings="errors.warnings"
                    :staticUrl="staticUrl"
                    :accountId="accountId"
                />
                <button
                    class="btn btn-primary"
                    v-if="datasource_params && datasource_params.is_configured && datasource_params.can_schedule && (importFinished || noImportFound) && permissions.tiered_custom_import_start"
                    @click="requestUpdate()">
                  Request Update
                </button>
              </div>
            </div>
          </div>
        `,
}

export {ScrapeData};
