import Vue from 'vue';
import VueFormulate from "@braid/vue-formulate";

// Utility functions
import { debounce } from "lodash";
import { validateUrl } from '../../../../common/helpers.js';

Vue.use(VueFormulate);

const WooCommerce = {
    name: "WooCommerce",
    props: {
        staticUrl: String,
        websiteDetails: Object,
        importTypesEnum: Object,
        importType: String,
        isSubmitting: Boolean,
        activePlatform: String,
        formatFieldName: {
            type: Function,
            default: (value) => value // Safe default
        }
    },
    data() {
        return {
            isSubmitted: false,
            form: {
                website_url: '',
                consumer_key: '',
                consumer_secret: ''
            },
            formLabels: {
                website_url: "Website URL"
            },
            errors: {},
        }
    },
    methods: {
        validateWebsiteUrl() {
            const result = validateUrl(this.form.website_url, false)
            if (result.error) {
                this.errors = {
                    ...this.errors,
                    website_url: result.error
                };
            } else {
                this.clearFieldError('website_url');
            }
        },
        validateConsumerField(fieldKey, prefix) {
            if (!this.form[fieldKey] || !this.form[fieldKey].startsWith(prefix)) {
                this.errors = {
                    ...this.errors,
                    [fieldKey]: `${fieldKey} must start with "${prefix}"`
                };
            } else {
                this.clearFieldError(fieldKey);
            }
        },
        clearFieldError(field) {
            if (Object.prototype.hasOwnProperty.call(this.errors, field)) {
                // Create a new object to avoid mutating the original
                // This is important because Vue.js reactivity system works by tracking changes 
                // in mutable objects. If we directly modify the original errors object,
                // Vue may not detect the change and trigger re-computation of dependent computed properties (hasErrors).
                const newErrors = { ...this.errors };
                delete newErrors[field];
                this.errors = newErrors;
            }
        },
        clearErrors() {
            this.errors = {};
        },
        formatName(fieldName) {
            return this.formLabels[fieldName] || this.formatFieldName(fieldName)
        }
    },
    mounted() {
        this.$emit("syncFormWithWebsiteDetails", this.form, this.clearFieldError, this.importType)
    },
    watch: {
        form: {
            deep: true,
            handler: debounce(function (newValue, oldValue) {
                this.$emit('onFormChange', { ...newValue }, this.importType)
            }, 500),
        }
    },
    computed: {
        isDoneSettingUp() {
            return this.websiteDetails.importType === this.importTypesEnum.woocommerce.value && this.websiteDetails.datasource_params?.is_configured
        },
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
        isShow: {
            get() {
                return this.activePlatform === this.importType;
            },
            set(newValue) {
                this.$emit('update:activePlatform', newValue);
            }
        }
    },
    template: `
    <b-card no-body class="mb-1 border-0 default-theme-border-radius">
        <b-card-header header-tag="header" v-b-toggle.woo-commerce-accordion class="p-2 border-0 bg-white d-flex align-items-center eggplant-color default-theme-border-radius cursor-pointer" role="button">
            <img class="import-type-icon mr-3" :src="staticUrl + 'img/data_source/woocommerce.png'" />
            <p class="m-0 text-left">
                <span class="font-weight-800">WooCommerce</span>
                <span>Import items directly from your WooCommerce store</span>
            </p>
        </b-card-header>

        <b-collapse id="woo-commerce-accordion" accordion="import-type-accordion" role="tabpanel" @show="$emit('show')" v-model="isShow">
            <b-card-body class="position-relative">

                <div v-if="isDoneSettingUp">
                
                </div>

                <div v-else>
                    <b-alert v-if="hasErrors" variant="danger" dismissible @dismissed="clearErrors" show>
                        <b class="alert-heading">Validation Errors</b>
                        <ul class="mb-0">
                            <li v-for="(message, fieldName) in errors" :key="fieldName">
                                {{ formatName(fieldName) }}: 
                                {{ message }}
                            </li>
                        </ul>
                    </b-alert>
                    <p>
                        Enter your credentials below
                        <font-awesome-icon id="credentials-explain" icon="fas fa-info-circle" class="ml-1" />
                        <b-tooltip target="credentials-explain" triggers="hover">
                            Located in Settings > Advanced > Add Key
                        </b-tooltip>
                    </p>
                    
                    <div class="form-group">
                        <FormulateInput
                        :disabled="isSubmitting"
                        label="Website URL"
                        v-model="form.website_url"
                        id="website-url"
                        type="text"
                        name="website_url"
                        placeholder="https://www.yourstore.com/"
                        :input-class="'form-control border-0 mb-0'"
                        :label-class="'col-3'"
                        :wrapper-class="'row align-items-baseline'"
                        :element-class="'d-flex border align-items-center rounded col-8 p-0'"
                        :outer-class="'mb-3'"
                        required
                        @blur="validateWebsiteUrl"
                        :autofocus="true" />

                        <FormulateInput
                        :disabled="isSubmitting"
                        label="Consumer Key"
                        v-model="form.consumer_key"
                        id="consumer-key"
                        type="text"
                        name="consumer_key"
                        placeholder="ck_1A1A1A1A1A1A1A"
                        :input-class="'form-control border-0 mb-0'"
                        :label-class="'col-3'"
                        :wrapper-class="'row align-items-baseline'"
                        :element-class="'d-flex border align-items-center rounded col-8 p-0'"
                        :outer-class="'mb-3'"
                        required
                        @blur="validateConsumerField('consumer_key', 'ck_')"
                        :autofocus="true" />

                        <FormulateInput
                        :disabled="isSubmitting"
                        label="Consumer Secret"
                        v-model="form.consumer_secret"
                        id="consumer-secret"
                        type="text"
                        name="consumer_secret"
                        placeholder="cs_1A1A1A1A1A1A1A"
                        :input-class="'form-control border-0 mb-0'"
                        :label-class="'col-3'"
                        :wrapper-class="'row align-items-baseline'"
                        :element-class="'d-flex border align-items-center rounded col-8 p-0'"
                        :outer-class="'mb-3'"
                        required
                        @blur="validateConsumerField('consumer_secret', 'cs_')"
                        :autofocus="true" />
                        
                    </div>
                </div>
            </b-card-body>
        </b-collapse>
    </b-card>
    `
}

export { WooCommerce };
