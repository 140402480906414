import Vue from 'vue';
import VueFormulate from "@braid/vue-formulate";

// Utility functions
import { debounce } from "lodash";
import { validateUrl } from '../../../../common/helpers.js';
import { TierUpgradeButton } from '../../../../tiers/TierUpgradeButton.js';
import { DogPlusSvg } from '../../../../tiers/components/DogPlusSvg.js';
import { usePermissionsStore } from '../../../../store/PermissionStore.js';

Vue.use(VueFormulate);

const SiteWalk = {
    name: "SiteWalk",
    props: {
        staticUrl: String,
        websiteDetails: Object,
        importTypesEnum: Object,
        importType: String,
        isSubmitting: Boolean,
        websiteName: String,
        accountId: String,
        billingPageUrl: String,
        hasSiteWalkPermission: Boolean,
        activePlatform: String,
        formatFieldName: {
            type: Function,
            default: (value) => value // Safe default
        }
    },
    data() {
        return {
            permissionsStore : usePermissionsStore(),
            isSubmitted: false,
            form: {
                website_url: ''
            },
            formLabels: {
                website_url: "Website URL"
            },
            errors: {},
        }
    },
    methods: {
        validateUrl(fieldName) {
            const result = validateUrl(this.form[fieldName], false)
            if (result.error) {
                this.errors = {
                    ...this.errors,
                    [fieldName]: result.error
                };
            } else {
                this.clearFieldError(fieldName);
            }
        },
        clearFieldError(field) {
            if (Object.prototype.hasOwnProperty.call(this.errors, field)) {
                // Create a new object to avoid mutating the original
                // This is important because Vue.js reactivity system works by tracking changes 
                // in mutable objects. If we directly modify the original errors object,
                // Vue may not detect the change and trigger re-computation of dependent computed properties (hasErrors).
                const newErrors = { ...this.errors };
                delete newErrors[field];
                this.errors = newErrors;
            }
        },
        clearErrors() {
            this.errors = {};
        },
        formatName(fieldName) {
            return this.formLabels[fieldName] || this.formatFieldName(fieldName)
        },
        handleHeaderClick(e) {
            if (!this.hasSiteWalkPermission) {
                e.stopPropagation();
                e.preventDefault();
                window.open(this.billingPageUrl, '_blank');
                return;
            }
        },
    },
    mounted() {
        this.$emit("syncFormWithWebsiteDetails", this.form, this.clearFieldError, this.importType)
    },
    watch: {
        form: {
            deep: true,
            handler: debounce(function (newValue) {
                this.$emit('onFormChange', { ...newValue }, this.importType)
            }, 500),
        },
        hasErrors: {
            deep: true,
            handler: function () {
                this.$emit('updateFormError', this.importType, this.errors)
            },
        }
    },
    computed: {
        isDoneSettingUp() {
            return this.websiteDetails.importType === this.importTypesEnum.spider.value && this.websiteDetails.datasource_params?.is_configured
        },
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
        isShow: {
            get() {
                return this.activePlatform === this.importType;
            },
            set(newValue) {
              this.$emit('update:activePlatform', newValue);
            }
        }
    },
    components: {
        TierUpgradeButton,
        DogPlusSvg
    },
    template: `
    <b-card no-body class="site-walk-card mb-1 border-0 default-theme-border-radius" :class="hasSiteWalkPermission ? 'bg-white' : 'light-sage-background'">
        <b-card-header 
        header-tag="header" 
        :class="hasSiteWalkPermission ? 'bg-white' : 'light-sage-background'"
        v-b-toggle.site-walk-accordion
        class="p-2 border-0 d-flex align-items-center eggplant-color default-theme-border-radius cursor-pointer"
        role="button"
        @click.stop="(e) => handleHeaderClick(e)"
        >
            <img class="import-type-icon mr-3" :src="staticUrl + 'img/data_source/spider.png'" />
            <p class="m-0 text-left">
                <span class="font-weight-800">Site Walk</span>
                <span>Have AdRetriever walk your public website to retrieve items</span>
            </p>
            <div v-if="!hasSiteWalkPermission" class="btn-tier-restriction ml-2">
                <DogPlusSvg fillColorClass="teal-lk-bone" />
            </div>
        </b-card-header>

        <b-collapse v-if="hasSiteWalkPermission" id="site-walk-accordion" accordion="import-type-accordion" v-model="isShow" role="tabpanel" @show="$emit('show')">
            <b-card-body class="position-relative">
                <div v-if="isDoneSettingUp">
                
                </div>

                <div v-else>
                    <b-alert v-if="hasErrors" variant="danger" dismissible @dismissed="clearErrors" show>
                        <b class="alert-heading">Validation Errors</b>
                        <ul class="mb-0">
                            <li v-for="(message, fieldName) in errors" :key="fieldName">
                                {{ formatName(fieldName) }}: 
                                {{ message }}
                            </li>
                        </ul>
                    </b-alert>
                    
                    <div class="form-group">
                        <p>Enter your website below</p>
                        <FormulateInput
                        v-model="form.website_url"
                        :disabled="isSubmitting"
                        label="Website URL"
                        id="website-url"
                        type="text"
                        name="website_url"
                        placeholder="https://www.yoursite.com/"
                        :input-class="'form-control border-0 mb-0'"
                        :label-class="'col-3'"
                        :wrapper-class="'row align-items-baseline'"
                        :element-class="'d-flex border align-items-center rounded col-8 p-0'"
                        :outer-class="'mb-3'"
                        required
                        :autofocus="true"
                        @blur="validateUrl('website_url')" />
                    </div>
                </div>
            </b-card-body>
        </b-collapse>
    </b-card>
    `
}

export { SiteWalk };
