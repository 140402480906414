
import { IntervalSelector } from "../IntervalSelector.js"
import { WebsitesService } from "../common/api.service.js"

let InventorySchedule = {
    name: "InventorySchedule",
    props: {
        intervalSelector: Object,
    },
    data() {
        return {
            isSubmitted: false,
            isSubmitSuccess: null
        }
    },
    components: {
        IntervalSelector
    },
    methods: {
        onUpdateValues(interval_selected, day_week_selected, day_month_selected) {
            this.intervalSelector.interval_selected = interval_selected;
            this.intervalSelector.day_week_selected = day_week_selected;
            this.intervalSelector.day_month_selected = day_month_selected;
        },
        onSubmit() {
            WebsitesService.update(`${this.intervalSelector.websiteName}/`, {
                "interval": parseInt(this.intervalSelector.interval_selected),
                "scrape_interval_day_week": parseInt(this.intervalSelector.day_week_selected),
                "scrape_interval_day_month": parseInt(this.intervalSelector.day_month_selected)
            }).then((response) => {
                this.isSubmitted = true
                this.isSubmitSuccess = true
            }).catch((error) => {
                this.isSubmitted = true
                this.isSubmitSuccess = false
            })
        }
    },
    template: `
    <div class="border p-3 bg-white mt-3 default-theme-border-radius">
        <div class="border-bottom">
            <h5>Schedule</h5>
        </div>
        <div class="mt-3" v-if="isSubmitted">
            <b-alert v-if="isSubmitSuccess" variant="success" show>Schedule updated!</b-alert>
            <b-alert v-if="!isSubmitSuccess" variant="danger" show>Schedule update failed.</b-alert>
        </div>
        <b-form @submit.prevent="onSubmit">
            <IntervalSelector v-bind={...intervalSelector} @updateValues="onUpdateValues" />
            <b-button type="submit" variant="primary" class="ml-3">Save</b-button>
        </b-form>
    </div>
    `
}

export { InventorySchedule }