import Vue from 'vue';
import VueFormulate from "@braid/vue-formulate";

// Utility functions
import { debounce } from "lodash";

Vue.use(VueFormulate);

const Shopify = {
    name: "Shopify",
    props: {
        staticUrl: String,
        websiteDetails: Object,
        importTypesEnum: Object,
        importType: String,
        activePlatform: String,
        formatFieldName: {
            type: Function,
            default: (value) => value // Safe default
        }
    },
    data() {
        return {
            isSubmitted: false,
            form: {
                shop_name: ''
            },
            formLabels: {
                shop_name: "Store Name"
            },
            errors: {},
        }
    },
    watch: {
        form: {
            deep: true,
            handler: debounce(function (newValue, oldValue) {
                this.$emit('onFormChange', {...newValue}, this.importType)  
            }, 500),
        },
    },
    computed: {
        isDoneSettingUp () {
            return this.websiteDetails.importType === this.importTypesEnum.shopify.value && this.websiteDetails.datasource_params?.is_configured
        },
        isShow: {
            get() {
                return this.activePlatform === this.importType;
            },
            set(newValue) {
                this.$emit('update:activePlatform', newValue);
            }
        },
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
    },
    methods: {
        validateShopName() {
            // Remove any whitespace and convert to lowercase
            const cleanShopName = this.form.shop_name.trim().toLowerCase();
            const result = cleanShopName.length > 0 &&
            /^[a-z0-9][a-z0-9-]*[a-z0-9]$/.test(cleanShopName);

            if (!result) {
                this.errors = {
                    ...this.errors,
                    shop_name: "Please enter a valid store name. Store name can only contain letters, numbers, and hyphens."
                };
            } else {
                this.clearFieldError('shop_name');
            }
        },
        clearFieldError(field) {
            if (Object.prototype.hasOwnProperty.call(this.errors, field)) {
                // Create a new object to avoid mutating the original
                // This is important because Vue.js reactivity system works by tracking changes 
                // in mutable objects. If we directly modify the original errors object,
                // Vue may not detect the change and trigger re-computation of dependent computed properties (hasErrors).
                const newErrors = { ...this.errors };
                delete newErrors[field];
                this.errors = newErrors;
            }
        },
        clearErrors() {
            this.errors = {};
        },
        formatName(fieldName) {
            return this.formLabels[fieldName] || this.formatFieldName(fieldName)
        }
    },
    mounted() {
        this.$emit("syncFormWithWebsiteDetails", this.form, this.clearFieldError, this.importType)
    },
    template: `
    <b-card no-body class="mb-1 border-0 default-theme-border-radius">
        <b-card-header header-tag="header" v-b-toggle.shopify-accordion class="p-2 border-0 bg-white d-flex align-items-center eggplant-color default-theme-border-radius cursor-pointer" role="button">
            <img class="import-type-icon mr-3" :src="staticUrl + 'img/data_source/shopify.png'" />
            <p class="m-0 text-left">
                <span class="font-weight-800">Shopify</span>
                <span>Import items directly from your Shopify store</span>
            </p>
        </b-card-header>

        <b-collapse id="shopify-accordion" accordion="import-type-accordion" role="tabpanel" @show="$emit('show')" v-model="isShow" >
            <b-card-body class="position-relative">

                <div v-if="isDoneSettingUp">
                
                </div>

                <div v-else>
                    <b-alert v-if="hasErrors" variant="danger" dismissible @dismissed="clearErrors" show>
                        <b class="alert-heading">Validation Errors</b>
                        <ul class="mb-0">
                            <li v-for="(message, fieldName) in errors" :key="fieldName">
                                {{ formatName(fieldName) }}: 
                                {{ message }}
                            </li>
                        </ul>
                    </b-alert>
                    <p>
                        Enter your store name
                        <font-awesome-icon id="store-name-explain" icon="fas fa-info-circle" class="ml-1" />
                        <b-tooltip target="store-name-explain" triggers="hover">
                            In General Settings, find your store name under &quot;Store name&quot;.
                        </b-tooltip>
                    </p>
                    
                    <div class="form-group d-flex align-items-center">
                        <FormulateInput
                        v-model="form.shop_name"
                        id="store-name"
                        type="text"
                        name="shop_name"
                        placeholder="store-name"
                        :input-class="'form-control border-0 mb-0'"
                        :element-class="'d-flex border align-items-center rounded'"
                        :outer-class="'mb-0'"
                        required
                        @blur="validateShopName"
                        :autofocus="true">
                            <div slot="prefix" class="p-2 gray-200-bg">
                                https://
                            </div>
                        </FormulateInput>
                        <span slot="suffix">
                            .myshopify.com
                        </span>
                    </div>


    
                
                </div>
                


                


            </b-card-body>
        </b-collapse>
    </b-card>
    `
}

export { Shopify };
