const SampleData = {
    name: "SampleData",
    props: {
        importType: String,
        activePlatform: String,
        importTypesEnum: Object,
        getSampleData: {
            type: Function,
            default: (value) => value // Safe default
        }
    },
    computed: {
        isShow: {
            get() {
                return this.activePlatform === this.importType;
            },
            set(newValue) {
                this.$emit('update:activePlatform', newValue);
            }
        }
    },
    template: `
    <b-card no-body class="mb-1 border-0 default-theme-border-radius">
        <b-card-header header-tag="header" v-b-toggle.sample-data-accordion class="p-2 border-0 bg-white d-flex align-items-center eggplant-color default-theme-border-radius" role="button">
            <font-awesome-icon class="import-type-icon mr-3" :icon="['fas', 'box-open']" />
            <p class="m-0 text-left">
                <span class="font-weight-800">Sample Data</span>
                <span>Take AdRetriever for a test run with sample data</span>
            </p>
        </b-card-header>

        <b-collapse id="sample-data-accordion" accordion="import-type-accordion" role="tabpanel" @show="$emit('show')" v-model="isShow">
            <b-card-body class="position-relative">
                <div>
                    <p>
                        <span>Your advertiser will be populated with sample items to get you up and running.</span>
                        <span class="d-block">You can also <a href="#" @click="getSampleData()">download our sample data</a>, modify it, and import it using the Spreadsheet data source option.</span>
                    </p>
                </div>
            </b-card-body>
        </b-collapse>
    </b-card>
    `
}

export { SampleData };