import Vue from 'vue';
import VueFormulate from "@braid/vue-formulate";
import Masonry from 'masonry-layout';
import { SingleAdPreview } from './SingleAdPreview.js';

Vue.use(VueFormulate);

const AdEditHtmlAdPreview = {
    data: function () {
        return {
            isSaving: false,
            sizes: {},
            _width: null,
            showingFirstIframe: true,
            static_url: adretriever.STATIC_URL,
            isLoadingAd: false,
            isFirstSetupSizesDone: false
        }
    },

    props: {
        store: Object,
        base_preset_preview_url: String,
        returnURL: String,
        website: String,
        isShowTitleAndRefresh: {
            type: Boolean,
            default: true
        }
    },

    mounted: function () {
        // Initialize Masonry
        new Masonry('#masonry-container', {
          itemSelector: '.ad-iframe',
          columnWidth: 160,
          horizontalOrder: true
        });
        this.getSizes();

    },

    methods: {
        async getSizes() {
            this.toggleLoadingAd();
            this.resetSizes();
            this.populateSizes(this.store.ad.theme_config.theme_version);
            this.sortSizes();
        },

        toggleLoadingAd() {
            if (!this.isLoadingAd) this.isLoadingAd = true;
        },

        isVersionMismatch(theme) {
            return theme.number !== theme.latest_version;
        },


        resetSizes() {
            if (this.sizes != {}) this.sizes = {};
        },

        populateSizes(theme) {
            if(theme == null){
                return
            }
            this.sizes = {};

            theme.templates.forEach(template => {
                this.sizes[template.id] = template.size;
            });
        },

        sortSizes() {
            this.sizes = Object.fromEntries(
                Object.entries(this.sizes).sort((a, b) => a[1].localeCompare(b[1]))
            );
            if(!this.isFirstSetupSizesDone) this.isFirstSetupSizesDone = true
        },

        forceRefresh() {
            // Add a random custom field which should be enough to change the preview URL
            this.store.updateCustomField('random', (Math.random()*100000).toFixed(0).toString());
            this.isLoadingAd = true
        },
        height: function(size) {
            return size.split('X')[1].trim();
        },
        width: function(size) {
            return size.split('X')[0].trim();
        },
        // trigger transition to new preview iframe
        displayIframe() {
            this.showingFirstIframe = !this.showingFirstIframe
        },
        // reset any previewURL that has value to clear hidden iframe to save resources
        clearPreviewUrlValue(previewUrlName) {
            this[previewUrlName] = ""
        },
        updateIsLoadingAd(val) {
            this.isLoadingAd = val
        }
    },

    computed: {
        // Only add margin for large size ad preview will blocking by the size selection area
        isHeightExceed: function () {
            if(this.$refs.parentContainer)  {
                return this.height > this.$refs.parentContainer.clientHeight
            }else{
                //give a default value in case the parent container is not loaded
                return this.height > 650
            }
        },
        // Control class when ad preview width is wider than parent container
        isWidthExceed: function () {
            if(this.$refs.parentContainer)  {
                return this.width > this.$refs.parentContainer.clientWidth
            }else{
                //give a default value in case the parent container is not loaded
                return this.width > 650
            }
        },
    },

watch: {
    "store.ad.theme_config.theme_version": {
        immediate: true,
        deep: true,
        handler(newValue, oldValue) {
            if(this.isFirstSetupSizesDone) this.getSizes()
        }
    }
},
    components: {
        SingleAdPreview,
    },

    template:
        `
    <div ref="parentContainer">
      <div v-if="isShowTitleAndRefresh" class="w-75 h-100 ml-1 p-2 eggplant-color">
                <h5 class="p-0 d-inline-block">Ad Preview</h5>
                <button type="button" class="btn refresh-button p-0 mb-2 ml-3" @click="forceRefresh()" :disabled="isLoadingAd">
                    <font-awesome-icon class="eggplant-color" :icon="['fas', 'arrows-rotate']" />
                </button>
      </div>
      <div class="row mx-2 pr-3" id="masonry-container">
        <div class="p-2 masonry-item" v-for="(size, template_id) in sizes">
            <SingleAdPreview 
                :basePresetPreviewUrl="'/api/ad-preview/'"
                :size="size"
                :store="store"
                :base_preset_preview_url="base_preset_preview_url"
                :key="template_id"
                @updateIsLoadingAd="updateIsLoadingAd"
            />
        </div>
      </div>
    </div>
    `
}

export {AdEditHtmlAdPreview};
